import axios from 'axios'
import { extractError } from '../utils/errorHandler'
import { API_SERVER_RETENTION } from '../../config/app.config'

export const getByWorkStationId = async (workStationId) => {
  try {
    const query = { workStationId }
    const { data: productionDetails } = await axios.get('productionDetails/real-time', {
      params: query,
    })
    return productionDetails
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getWorkStationOrdersExecuting = async (workStationId) => {
  try {
    const query = { workStationId }
    const { data: productionDetails } = await axios.get('productionDetails/currentOrders', {
      params: query,
    })
    return productionDetails
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getWorkStationOrdersPending = async (workStationId) => {
  try {
    const query = { workStationId }
    const { data: productionDetails } = await axios.get('productionDetails/currentOrders', {
      params: query,
    })
    return productionDetails
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getGoalsByWorkStationId = async (workStationId) => {
  try {
    const query = { workStationId }
    const { data: productionDetails } = await axios.get(`productionDetails/goals/${workStationId}`)
    return productionDetails
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getCurrentOrder = async (workStationId) => {
  try {
    const query = { workStationId }
    const { data: currentOrder } = await axios.get('productionDetails/currentOrder', {
      params: query,
    })
    return currentOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAssignedOrders = async (workStationId) => {
  try {
    const query = { workStationId }
    const { data: assignedOrders } = await axios.get('productionDetails/assignedOrders', {
      params: query,
    })
    return assignedOrders
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const updateProcessExecution = async (processExecution) => {
  try {
    const { data } = await axios.put('processExecution', processExecution)
    return data
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getProcessExecution = async (id) => {
  try {
    const { data: process } = await axios.get(`processExecution/${id}`)
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const updateWorkOrderExecution = async (processExecution) => {
  try {
    const { data } = await axios.put('workOrderExecution', processExecution, {
      baseURL: API_SERVER_RETENTION,
    })
    return data
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getWorkOrderExecution = async (id) => {
  try {
    const { data: process } = await axios.get(`workOrderExecution/${id}`, {
      baseURL: API_SERVER_RETENTION,
    })
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
