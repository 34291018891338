import React, { useState } from 'react'
import { FlexColumn, Label, Text } from 'styles/components'
import { Tabs } from 'antd'
import { TreeTransfer } from 'components/tree-transfer'
import { useEffect } from 'react'

const mapAreasWorkStations = (areas, workStations) => {
  return areas
    .map((area) => {
      const areaWorkStations = workStations
        .filter((workStation) => {
          return workStation.areaId === area.id
        })
        .sort((a, b) => {
          return a.description.localeCompare(b.description)
        })

      return {
        key: `area-${area.id}`,
        title: area.description,
        children: areaWorkStations.map((workStation) => {
          return {
            key: `${workStation.id}`,
            title: workStation.description,
          }
        }),
      }
    })
    .filter((area) => {
      return area.children.length > 0
    })
}

export const EmployeeNotifications = ({
  updatedEmployee,
  areas,
  workStations,
  setUpdatedEmployee,
}) => {
  const areasMap = mapAreasWorkStations(areas, workStations)

  const [targetKeys, setTargetKeys] = useState({
    maintenanceAlerts: [],
    qualityAlerts: [],
    logisticsAlerts: [],
  })

  const onChange = (keys, name) => {
    setTargetKeys((prev) => {
      return {
        ...prev,
        [name]: keys,
      }
    })
  }

  useEffect(() => {
    const maintenanceAlerts = updatedEmployee.maintenanceAlerts
    const qualityAlerts = updatedEmployee.qualityAlerts
    const logisticsAlerts = updatedEmployee.logisticsAlerts

    if (maintenanceAlerts.some((wo) => wo.includes('area'))) return
    if (
      maintenanceAlerts.length === 0 &&
      qualityAlerts.length === 0 &&
      logisticsAlerts.length === 0
    )
      return
    for (const area of areasMap) {
      const areaWorkStations = area.children.map((workStation) => workStation.key)
      if (areaWorkStations.every((wo) => maintenanceAlerts.includes(wo))) {
        maintenanceAlerts.push(area.key)
      }

      if (areaWorkStations.every((wo) => qualityAlerts.includes(wo))) {
        qualityAlerts.push(area.key)
      }

      if (areaWorkStations.every((wo) => logisticsAlerts.includes(wo))) {
        logisticsAlerts.push(area.key)
      }
    }

    setTargetKeys({
      maintenanceAlerts,
      qualityAlerts,
      logisticsAlerts,
    })
    // check is if all workstations are selected and mark the area as selected
  }, [updatedEmployee])

  useEffect(() => {
    setUpdatedEmployee((prev) => ({ ...prev, ...targetKeys }))
  }, [targetKeys])

  const tabs = [
    {
      title: 'Alertas de manutenção',
      name: 'maintenanceAlerts',
    },
    {
      title: 'Alertas de qualidade',
      name: 'qualityAlerts',
    },
    {
      title: 'Alertas de logística',
      name: 'logisticsAlerts',
    },
  ]

  const getWorkStations = (name) => {
    return (
      <TreeTransfer
        dataSource={areasMap}
        targetKeys={targetKeys[name]}
        onChange={(values) => onChange(values, name)}
      />
    )
  }

  return (
    <FlexColumn marginTop={10} align={'normal'}>
      <FlexColumn align={'normal'} marginBottom={10}>
        <Label>Selecione quais notificações você deseja receber</Label>
        <Text fontSize={'0.9'}>Para o recebimento de notificações cadastre o número celular</Text>
      </FlexColumn>
      <Tabs
        defaultActiveKey="1"
        type="line"
        size={3}
        style={{ width: '100%', overflow: 'auto' }}
        tabPosition="left"
        items={tabs.map((item, index) => {
          const id = String(index + 1)
          return {
            label: item.title,
            key: id,
            children: getWorkStations(item.name),
          }
        })}
      />
    </FlexColumn>
  )
}
