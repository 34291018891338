import React, { Component, Suspense, lazy } from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { inject, observer } from 'mobx-react'

/* loader component for Suspense*/
import Spinner from './components/UI/Spinner/Spinner'

import Base from './components/Layout/Base'
import BasePage from './components/Layout/BasePage'
import ProtectedRoute from './components/ProtectedRoute/'
import DynamicRedirect from './components/DynamicRedirect/'

//Lazy Imports
const Login = lazy(() => import('./containers/Auth/Login'))
const LoginCallback = lazy(() => import('./containers/Auth/LoginCallback'))
const LoginSilentCallback = lazy(() => import('./containers/Auth/LoginSilentCallback'))
const Logout = lazy(() => import('./containers/Auth/Logout'))
const LogoutCallback = lazy(() => import('./containers/Auth/LogoutCallback'))
const RecoverPassword = lazy(() => import('./containers/Auth/RecoverPassword'))
const ChangePassword = lazy(() => import('./containers/Auth/RecoverPassword/changePassword'))

const Onboarding = lazy(() => import('./containers/Onboarding/'))
const Home = lazy(() => import('./containers/Home/'))
const Post = lazy(() => import('./containers/Home/post'))
const WorkStation = lazy(() => import('./containers/WorkStation/workStation'))
const WorkStationOverview = lazy(() => import('./containers/WorkStation/overview/'))
const Planning = lazy(() => import('./containers/Planning/'))
const Overview = lazy(() => import('./containers/Planning/Overview/'))
const RealTime = lazy(() => import('./containers/RealTime/'))
const RealTimeKanban = lazy(() => import('./containers/RealTimeKanban/'))
const WorkOrderDashboard = lazy(() => import('./containers/Dashboard/WorkOrder/WorkOrderDashboard'))
const MaterialsSupply = lazy(() => import('./containers/Tasks/MaterialsSupply/'))
const QualityInspection = lazy(() => import('./containers/Tasks/QualityInspection/'))

const SetupProduction = lazy(() => import('./containers/Setup/SetupProduction/'))
const SetupProductionInspection = lazy(() =>
  import('./containers/Setup/SetupProductionInspections/')
)
const SetupTools = lazy(() => import('./containers/Setup/SetupTool/'))
const SetupEmployee = lazy(() => import('./containers/Setup/SetupEmployee/'))
const SetupShift = lazy(() => import('./containers/Setup/SetupShift/'))
const SetupWorkStation = lazy(() => import('./containers/Setup/SetupWorkStation/'))
const SetupArea = lazy(() => import('./containers/Setup/SetupArea/'))
const SetupMaintenance = lazy(() => import('./containers/Setup/SetupMaintenance/'))
const SetupProcessExecution = lazy(() => import('./containers/Setup/SetupProcessExecution/'))
const SetupPauses = lazy(() => import('./containers/Setup/Pauses/'))
const MaterialsKanban = lazy(() => import('./containers/Setup/MaterialsKanban/'))

const AdminUser = lazy(() => import('./containers/Admin/AdminUsers/'))

const NotFound = lazy(() => import('./components/Pages/NotFound'))
const Maintenance = lazy(() => import('./components/Pages/Maintenance'))
const SingleView = lazy(() => import('./components/SingleView//SingleView'))

const Print = lazy(() => import('./containers/WorkStation/print'))
const PrintSetup = lazy(() => import('./containers/Setup/Print'))

const ThirdPartyPowerAutomate = lazy(() => import('./containers/ThirdParty/PowerAutomate/i.index'))

const listOfPages = [
  '/notfound',
  '/maintenance',
  '/login',
  '/login-callback',
  '/login-silent-callback',
  '/logout',
  '/logout-callback',
  '/recover-password',
  '/change-password',
  '/print',
]

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />
@inject('rootStore')
@observer
class Routes extends Component {
  componentDidMount() {
    this.updateLastNavigatedUrl()
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: lastLocation },
    } = prevProps
    const {
      location: { pathname: currentLocation },
    } = this.props

    if (currentLocation !== lastLocation) this.updateLastNavigatedUrl()
  }

  updateLastNavigatedUrl() {
    const { location } = this.props
    if (![...listOfPages, '/'].includes(location.pathname.toLowerCase())) {
      localStorage.setItem('lastLocation', location.pathname + location.search)
    }
  }

  render() {
    const { initialized, getUserRolesInProcess } = this.props.rootStore.authStore
    const { location } = this.props
    const currentKey = location.pathname.split('/')[1] || '/'

    if (location.pathname.includes('/print')) {
      return (
        <Suspense fallback={<Spinner />}>
          <Print />
        </Suspense>
      )
    }

    const timeout = { enter: 500, exit: 500 }
    const animationName = 'rag-fadeIn'
    if (!initialized || getUserRolesInProcess) return <Spinner />
    console.debug(location)
    if (listOfPages.indexOf(location.pathname) > -1)
      return (
        <BasePage>
          <Suspense fallback={<Spinner />}>
            <Switch location={location}>
              <Route path="/notfound" component={waitFor(NotFound)} />
              <Route path="/maintenance" component={waitFor(Maintenance)} />
              <Route path="/login" component={waitFor(Login)} />
              <Route path="/login-callback" component={waitFor(LoginCallback)} />
              <Route path="/login-silent-callback" component={waitFor(LoginSilentCallback)} />
              <Route path="/logout" component={waitFor(Logout)} />
              <Route path="/logout-callback" component={waitFor(LogoutCallback)} />
              <Route path="/recover-password" component={waitFor(RecoverPassword)} />
              <Route path="/change-password" component={waitFor(ChangePassword)} />
              <Route component={waitFor(NotFound)} />
            </Switch>
          </Suspense>
        </BasePage>
      )
    else
      return (
        <Base>
          <TransitionGroup>
            <CSSTransition
              key={currentKey}
              timeout={timeout}
              classNames={animationName}
              exit={false}
            >
              <div>
                <Suspense fallback={<Spinner />}>
                  <Switch location={location}>
                    <ProtectedRoute
                      allowedRoles={['workstation', 'admin', 'superuser']}
                      path="/power-automate"
                      exact
                      component={waitFor(ThirdPartyPowerAutomate)}
                    />
                    <Redirect from="/home" to="/" />
                    <ProtectedRoute
                      allowedRoles={[
                        'workstation',
                        'admin',
                        'superuser',
                        'planning',
                        'realtimetracking',
                        'manufacturing_eng',
                      ]}
                      path="/"
                      exact
                      component={waitFor(Home)}
                    />
                    <ProtectedRoute
                      allowedRoles={[
                        'workstation',
                        'admin',
                        'superuser',
                        'planning',
                        'realtimetracking',
                        'manufacturing_eng',
                      ]}
                      path="/onboarding"
                      component={waitFor(Onboarding)}
                    />
                    <Redirect from="/post/index/:id?" to="/post/:id?" />
                    <ProtectedRoute
                      allowedRoles={[
                        'planning',
                        'realtimetracking',
                        'workStation',
                        'admin',
                        'superuser',
                      ]}
                      path="/post/:id?"
                      component={waitFor(Post)}
                    />
                    <ProtectedRoute
                      allowedRoles={[
                        'planning',
                        'realtimetracking',
                        'workStation',
                        'admin',
                        'superuser',
                      ]}
                      path="/workStation/overview"
                      component={waitFor(WorkStationOverview)}
                    />
                    <ProtectedRoute
                      allowedRoles={['planning', 'realtimetracking', 'admin', 'superuser']}
                      path="/workStation/overview/:id?"
                      component={waitFor(WorkStationOverview)}
                    />
                    <Redirect from="/workStation/index/:id?" to="/workStation/:id?" />
                    <ProtectedRoute
                      allowedRoles={['workstation', 'admin', 'superuser']}
                      path="/workStation/:id?"
                      component={waitFor(WorkStation)}
                    />
                    <ProtectedRoute
                      allowedRoles={['planning', 'realtimetracking', 'admin', 'superuser']}
                      path="/planning/overview/:id?"
                      component={waitFor(Overview)}
                    />
                    <Redirect from="/planning/index/:id?" to="/planning/:id?" />
                    <ProtectedRoute
                      allowedRoles={['realtimetracking', 'planning', 'admin', 'superuser']}
                      path="/planning/:id?"
                      component={waitFor(Planning)}
                    />
                    <ProtectedRoute
                      allowedRoles={['realtimetracking', 'planning', 'admin', 'superuser']}
                      path="/dashboard/work-order"
                      component={waitFor(WorkOrderDashboard)}
                    />
                    <ProtectedRoute
                      allowedRoles={['realtimetracking', 'planning', 'admin', 'superuser']}
                      path="/real-time-dashboard"
                      component={waitFor(RealTime)}
                    />
                    <Redirect from="/realtimetracking" to="/real-time" />
                    <ProtectedRoute
                      allowedRoles={['realtimetracking', 'planning', 'admin', 'superuser']}
                      path="/real-time"
                      component={waitFor(RealTimeKanban)}
                    />
                    <ProtectedRoute
                      allowedRoles={['workstation', 'admin', 'superuser']}
                      path="/tasks/materials-supply"
                      component={waitFor(MaterialsSupply)}
                    />
                    <ProtectedRoute
                      allowedRoles={['workstation', 'admin', 'superuser']}
                      path="/tasks/quality-inspection"
                      component={waitFor(QualityInspection)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/production"
                      component={waitFor(SetupProduction)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/production-inspection"
                      component={waitFor(SetupProductionInspection)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/tools"
                      component={waitFor(SetupTools)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/employee"
                      component={waitFor(SetupEmployee)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/shift"
                      component={waitFor(SetupShift)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/workStation"
                      component={waitFor(SetupWorkStation)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/area"
                      component={waitFor(SetupArea)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/maintenance"
                      component={waitFor(SetupMaintenance)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/processExecution"
                      component={waitFor(SetupProcessExecution)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/pauses"
                      component={waitFor(SetupPauses)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/tags"
                      component={waitFor(PrintSetup)}
                    />
                    <ProtectedRoute
                      allowedRoles={['manufacturing_eng', 'admin', 'superuser']}
                      path="/setup/materials-kanban"
                      component={waitFor(MaterialsKanban)}
                    />
                    <ProtectedRoute
                      allowedRoles={['superuser']}
                      path="/admin/user"
                      component={waitFor(AdminUser)}
                    />
                    <ProtectedRoute
                      allowedRoles={['superuser']}
                      path="/singleview"
                      component={waitFor(SingleView)}
                    />
                    <ProtectedRoute path="/logout" component={waitFor(Logout)} />
                    <DynamicRedirect />
                    <Route component={waitFor(NotFound)} />
                  </Switch>
                </Suspense>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Base>
      )
  }
}
export default withRouter(Routes)
