import styled from 'styled-components'

export const SizedBox = styled.div`
  height: ${(props) => props.height || 20}px;
  width: ${(props) => props.width || 0}px;
`

export const VerticalLine = styled.div`
  border-left: 2px solid #e4e4e4;
  margin: 35px 40px 35px -35px;

  @media (orientation: portrait) {
    display: none;
  }
`

export const HorizontalLine = styled.div`
  border-bottom: 1px solid #e4e4e4;
  margin: 15px 35px;
`

export const Padding = styled.div`
  padding: ${({ value }) => value ?? 0}px;
`
