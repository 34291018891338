import React, { Component } from 'react'
import { Header, Footer, Body, ModalWrapper } from './styles'

export default class Modal extends Component {
  static Header = Header
  static Footer = Footer
  static Body = Body

  render() {
    const { children, ...rest } = this.props
    return <ModalWrapper {...rest}>{children}</ModalWrapper>
  }
}
