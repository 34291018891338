import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAll = async () => {
  try {
    const { data: materialSupply } = await axios.get('materialSupply')
    return materialSupply
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getEarlierThanDate = (currentItem, perPage, date) => {
  return axios.get('materialSupply/earlierThanDate', { params: { currentItem, perPage, date } })
}

export const getForDate = (currentItem, perPage, date) => {
  return axios.get('materialSupply/forDate', { params: { currentItem, perPage, date } })
}

export const getBetweenDates = (currentItem, perPage, minDate, maxDate) => {
  return axios.get('materialSupply/betweenDates', {
    params: { currentItem, perPage, minDate, maxDate },
  })
}

export const getBySearch = (currentItem, perPage, minDate, maxDate, query, active, ids) => {
  return axios.get('materialSupply/search', {
    params: {
      currentItem,
      perPage,
      minDate,
      maxDate,
      query,
      active,
      ids: ids.length > 0 ? ids + '' : [],
    },
  })
}

export const completeTasks = async (materialTasks) => {
  try {
    const { data: materialSupplies } = await axios.post(
      'materialSupply/completeTasks',
      materialTasks.map((m) => ({
        ...m,
        quantity: m.updatedPayedQuantity || m.payedQuantity || m.quantity,
      }))
    )
    return materialSupplies
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const undoTasks = async (materialTasks) => {
  try {
    const { data: materialSupplies } = await axios.post('materialSupply/undoTasks', materialTasks)
    return materialSupplies
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const createMaterialSupplyPatternTasks = async () => {
  try {
    await axios.post('materialSupply/createMaterialSupplyPatternTasks')
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const refreshMaterialSupplyTasks = async () => {
  try {
    await axios.post('materialSupply/refreshMaterialSupplyTasks')
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
