import axios from 'axios'

export const update = async (quantity, serialNumber, id) => {
  return await axios.put('consumedRawMaterial', {
    quantity: parseFloat(quantity),
    serialNumber,
    id,
  })
}

export const terminate = async (id) => {
  return await axios.delete(`consumedRawMaterial?consumedRawMaterialId=${id}`)
}
