import styled, { css } from 'styled-components'
import {
  PrimarySelect,
  PageTitle,
  PrimaryInput,
  CreatableSelectWrapper,
  CreatableAsyncSelectWrapper,
} from 'styles/components'
import { Select } from 'antd'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 10px;

  ${({ width }) =>
    width &&
    css`
      width: ${width}%;
    `}

  ${PrimarySelect} {
    height: ${({ height }) => height ?? '48'}px;
  }

  ${CreatableSelectWrapper} {
    height: ${({ height }) => height ?? '48'}px;
  }

  ${CreatableAsyncSelectWrapper} {
    height: ${({ height }) => height ?? '48'}px;
  }

  ${({ height }) =>
    height &&
    css`
      margin-bottom: 0px;
      ${PrimaryInput} {
        height: ${height}px;
      }
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      &:after {
      }
    `}
`

export const FloatingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.principalWhite};
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8px;
  position: absolute;
  z-index: 2;
`

export const FloatingContainerFixed = styled.div`
  background-color: ${({ theme }) => theme.colors.principalWhite};
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8px;
  position: absolute;
  z-index: 2;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  position: fixed;
`

export const Label = styled(PageTitle)`
  line-height: 20px;
  font-weight: bold;
  font-size: 0.9em;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0px 0px 5px 0px;

  @media (max-width: 768px) {
    font-size: 0.7em;
  }

  @media (max-width: 425px) {
    font-size: 0.6em;
  }
`

export const Input = styled(PrimaryInput)`
  ${({ required }) =>
    required &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.warning};

      &:valid {
        border-bottom: 2px solid ${({ theme }) => theme.colors.baseInputBorderColor};
      }
    `}
`

export const SelectorTag = styled(Select)`
  min-height: 48px;
  margin-bottom: 10px !important;

  .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 48px !important;
    border-radius: 5px !important;
    border: none !important;
    background-color: #e1e1e1 !important;
    box-shadow: none !important;
    border-bottom: 2px solid #ababab !important;
    color: #2b2b2b !important;
  }

  .ant-select-selection-placeholder {
    color: #2b2b2b !important;
    opacity: 0.6;
  }
`
