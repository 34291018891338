/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

export const useSound = (file) => {
  const [audio] = useState(new Audio(file))
  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(false)

  const playSound = () => {
    setPlaying(!playing)
    setPlayed(true)
  }

  useEffect(() => {
    playing ? audio.play().catch(() => playSound()) : audio.pause()
  }, [playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  return [playing, playSound, played, setPlayed]
}
