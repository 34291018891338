import { success, error, warning } from 'styles/constants/snackBarConstants'
import { useSnackbar } from 'react-simple-snackbar'

export const useNotification = () => {
  const [defaultNotification] = useSnackbar()
  const [successNotification] = useSnackbar(success)
  const [errorNotification] = useSnackbar(error)
  const [warningNotification] = useSnackbar(warning)

  return [
    defaultNotification,
    successNotification,
    errorNotification,
    warningNotification
  ]
}