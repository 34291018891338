import axios from 'axios'
import queryString from 'query-string'
import { extractError } from '../utils/errorHandler'
import qs from 'qs'
import { API_SERVER_RETENTION } from '../../config/app.config'

export const getWorkOrderSuggestions = async (workStationId, workOrderId) => {
  try {
    const { data: suggestedOrders } = await axios.get(
      `workStationOrder/suggestions/${workStationId}/${workOrderId}`
    )
    return suggestedOrders
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getAssignableOrdersDashboard = async (
  workStationIds,
  query,
  showUnassignedWorkOrders,
  showStoppages,
  useExecutionTimeSuggestion,
  perPage,
  currentItem,
  isOverview = false
) => {
  try {
    const { data: workStationOrders } = await axios.get('workStationOrder/assignable-dashboard', {
      baseURL: API_SERVER_RETENTION,
      params: {
        currentItem,
        perPage,
        // showUnassignedWorkOrders,
        // showStoppages,
        workStationIds,
        useExecutionTimeSuggestion,
        query,
        // overviewPlanning: isOverview,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return workStationOrders
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAssignableOrders = async (
  workStationIds,
  query,
  showUnassignedWorkOrders,
  showStoppages,
  useExecutionTimeSuggestion,
  perPage,
  currentItem,
  isOverview = false,
  orderByDesc,
  promiseDateStart,
  promiseDateEnd
) => {
  try {
    const { data: workStationOrders } = await axios.get('workStationOrder/assignable', {
      baseURL: API_SERVER_RETENTION,
      params: {
        currentItem,
        perPage,
        showUnassignedWorkOrders,
        showStoppages,
        workStationIds,
        useExecutionTimeSuggestion,
        query,
        orderByDesc,
        overviewPlanning: isOverview,
        promiseDateStart,
        promiseDateEnd,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return workStationOrders
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAssignableOrdersExecution = async (
  workStationId,
  beginPlannedInterval,
  endPlannedInterval,
  areaId,
  useExecutionTimeSuggestion
) => {
  try {
    const { data: workStationOrders } = await axios.get('workStationOrder/assigned-executions', {
      baseURL: API_SERVER_RETENTION,
      params: {
        workStationId,
        beginPlannedInterval,
        endPlannedInterval,
        areaId,
        useExecutionTimeSuggestion,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return workStationOrders
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAssignableOrdersOperator = async (workStationIds, query, perPage, currentItem) => {
  try {
    const { data: workStationOrders } = await axios.get('workStationOrder/assignable', {
      params: {
        currentItem,
        perPage,
        showUnassignedWorkOrders: true,
        showStoppages: false,
        showNotFoundWorkOrders: false,
        workStationIds,
        query,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return workStationOrders
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAssignedOrders = async (
  workStationId,
  beginPlannedInterval,
  endPlannedInterval,
  areaId,
  useExecutionTimeSuggestion
) => {
  try {
    const search = queryString.stringify({
      workStationId,
      beginPlannedInterval,
      endPlannedInterval,
      areaId,
    })
    const { data: workStationOrders } = await axios.get(`workStationOrder/assigned?${search}`, {
      baseURL: API_SERVER_RETENTION,
    })
    return workStationOrders
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const schedule = async (workStationOrders) => {
  try {
    await axios.post('workStationOrder/schedule', workStationOrders)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getWorkOrderRuntimeStatus = async (assignedWorkStationId) => {
  try {
    return await axios.get(`workStationOrder/wokOrderRuntimeStatus/${assignedWorkStationId}`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const updateWorkOrderRuntimeStatus = async (workOrderRuntimeStatus) => {
  try {
    return await axios.patch('workOrderRuntimeStatus/workOrder', workOrderRuntimeStatus)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
