import styled, { css } from 'styled-components'
import { PageTitle } from './titles'
import { FlexColumn } from './wrapper'
import { CircleImage } from './images'
import { Rotate } from '../keyframes/animations'

export const Title = styled(PageTitle)`
  font-size: 3em;
  color: ${({ theme }) => theme.colors.principalColor};
`

export const Subtitle = styled(PageTitle)`
  line-height: 20px;
  font-weight: 600;
  font-size: 1em;
  white-space: break-spaces;
`

export const Resume = styled(FlexColumn)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.baseInputColor};
  align-items: start;
  padding: 20px;
  justify-content: start;
  position: relative;
`

export const ResumeTitle = styled(PageTitle)`
  font-size: 1.3em;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.principalColor};

  ${({ marged }) =>
    marged &&
    css`
      margin: 0px 0px 15px 0px !important;
    `}

  @media (max-width: 425px) {
    font-size: 1em;
  }
`

export const ResumeTile = styled(FlexColumn)`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
`

export const ResumeTileIcon = styled(FlexColumn)`
  margin-top: 10px;
  align-items: center;

  ${CircleImage} {
    max-width: 35px;
    margin-right: 10px;
  }
`

export const CircularLoader = styled.div`
  width: 100%;
  height: ${({ height }) => height ?? 100}px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    height: ${({ size }) => size ?? 30}px;
    width: ${({ size }) => size ?? 30}px;
    border-radius: 50%;
    justify-self: flex-start;
    display: table;
    border-left: 2px solid #fafafa;
    border-top: 2px solid #fafafa;
    border-bottom: 2px solid #c0c0c0;
    border-right: 2px solid #c0c0c0;
    margin-left: ${({ marginLeft }) => marginLeft ?? 10}px;
    animation: ${Rotate} 1s ease infinite;
  }
`

export const CircularLoaderSm = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 50%;
    justify-self: flex-start;
    display: table;
    border-left: 2px solid #fafafa;
    border-top: 2px solid #fafafa;
    border-bottom: 2px solid #2671ff;
    border-right: 2px solid #2671ff;
    margin-left: ${({ marginLeft }) => marginLeft ?? 10}px;
    animation: ${Rotate} 1s ease infinite;
  }
`