import axios from 'axios'
import { extractError } from '../utils/errorHandler'
import { AZURE_FUNCTIONS } from 'config/app.config'
import { API_SERVER_RETENTION } from '../../config/app.config'

export const getAll = async () => {
  try {
    const { data: employees } = await axios.get('employee', {
      baseURL: API_SERVER_RETENTION,
    })
    return employees
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllWithDeactivated = async () => {
  try {
    const { data: employees } = await axios.get('employee/getAllWithDeactivated')
    return employees
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getById = async (id) => {
  try {
    const { data: employee } = await axios.get(`employee/${id}`, {
      baseURL: API_SERVER_RETENTION,
    })
    return employee
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getByRegistration = async (registration) => {
  try {
    const { data: employee } = await axios.get(
      `employee/registration/${encodeURIComponent(registration)}`,
      {
        baseURL: API_SERVER_RETENTION,
      }
    )
    return employee
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getByEmail = async (email) => {
  try {
    const { data: employee } = await axios.get(`employee/email/${email}`, {
      baseURL: API_SERVER_RETENTION,
    })
    return employee
  } catch (err) {
    console.error(err)
    return null
  }
}

export const getByIdWithDeactivated = async (id) => {
  try {
    const { data: employee } = await axios.get(`employee/${id}/getByIdWithDeactivated`)
    return employee
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const remove = async (id) => {
  try {
    await axios.delete(`employee/${id}`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (employee) => {
  try {
    const { data: updatedEmployee } = await axios.put(`employee/${employee.id}`, employee)
    return updatedEmployee
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (employee) => {
  try {
    const { data: newEmployee } = await axios.post('employee', employee)
    return newEmployee
  } catch (err) {
    const apiError = extractError(err)
    if (apiError.ClassName === 'PlantScanner.API.Exceptions.DuplicateRegistrationException') {
      const registration = apiError.Data['registration']
      throw new Error(`A matrícula "${registration}" já está em uso, favor verificar a matrícula`)
    }
    if (apiError.ClassName === 'PlantScanner.API.Exceptions.DuplicateBadgeException') {
      const badge = apiError.Data['badge']
      throw new Error(`O crachá "${badge}" já está em uso, favor verificar o crachá`)
    }
    throw new Error(apiError.message || apiError.Message)
  }
}

export const activate = async (employee) => {
  try {
    await axios.post(`employee/${employee.id}/activateEmployee`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const deactivate = async (employee) => {
  try {
    await axios.post(`employee/${employee.id}/deactivateEmployee`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllStopStatus = async () => {
  try {
    const { data: allStopStatus } = await axios.get('employee/allStopStatus')
    return allStopStatus
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const uploadPhoto = async (photo, clientId, employeeId) => {
  let formData = new FormData()
  formData.append('profile', photo)
  try {
    const data = await fetch(
      `${AZURE_FUNCTIONS}/uploadProfilePhoto?code=805hD8/VwisREqYUvovx88mYKe2jd9WmgaQyNSAdnq0NowsqfUepqQ==&clientId=${clientId}&employeeId=${employeeId}`,
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }
    )
    return data
  } catch (err) {}
}

export const importEmployees = async (employees) => {
  try {
    const { data } = await axios.post('employee/import', employees, {
      baseURL: API_SERVER_RETENTION,
    })
    return data
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
