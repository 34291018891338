import axios from 'axios'
import { extractError } from '../utils/errorHandler'
import { API_SERVER_RETENTION } from '../../config/app.config'

export const getWeekIndicators = async () => {
  try {
    const { data: indicators } = await axios.get('home', {
      baseURL: API_SERVER_RETENTION,
    })
    // const { lastProducedOrders, weekIndicators } = indicators
    return indicators
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getOnboarding = async () => {
  try {
    const { data: indicators } = await axios.get('home/onboarding', {
      baseURL: API_SERVER_RETENTION,
    })
    // const { lastProducedOrders, weekIndicators } = indicators
    return indicators
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getLastUpdates = async () => {
  try {
    const { data: indicators } = await axios.get('home/news/list', {
      baseURL: API_SERVER_RETENTION,
    })
    // const { lastProducedOrders, weekIndicators } = indicators
    return indicators
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
