import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAll = async () => {
  try {
    const { data: programmedStoppagePatterns } = await axios.get('programmedStoppagePattern')
    return programmedStoppagePatterns
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (programmedStoppagePattern) => {
  try {
    const { data: updatedProgrammedStoppagePattern } = await axios.put(
      `programmedStoppagePattern/${programmedStoppagePattern.id}`,
      programmedStoppagePattern
    )
    return updatedProgrammedStoppagePattern
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (programmedStoppagePattern) => {
  try {
    const { data: newProgrammedStoppagePattern } = await axios.post(
      'programmedStoppagePattern',
      programmedStoppagePattern
    )
    return newProgrammedStoppagePattern
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
