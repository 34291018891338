export const workOrdersColors = {
  readyToSchedule: {
    background: '#FEFEFE',
    color: '#484848',
    border: '#e0e0e0',
  },
  machineLearning: {
    background: '#d4d4d582',
    color: '#484848',
    border: '#d4d4d582',
  },
  missingTools: {
    background: '#262626',
    color: '#fff',
  },
  scheduled: {
    background: '#3B86FF',
    color: '#fff',
    border: '#3B86FF',
  },
  late: {
    background: '#FF0000',
    color: '#fff',
    border: '#FF0000',
  },
  setup: {
    background: '#8500ff',
    color: '#fff',
    border: '#8500ff',
  },
  setupInExecution: {
    background: '#7c00ef',
    color: '#fff',
    border: '#7c00ef',
  },
  setupExecuted: {
    background: '#5a00ad',
    color: '#fff',
    border: '#5a00ad',
  },
  setupPaused: {
    background: '#5a00fd',
    color: '#fff',
    border: '#5a00fd',
  },
  inExecution: {
    background: '#FF902B',
    color: '#fff',
    border: 'rgba(0,0,0,.1)',
  },
  inExecutionPlanned: {
    background: 'rgba(255, 144, 43, 0.5)',
    color: '#fff',
    border: 'rgba(255, 144, 43, 0.7)',
  },
  setupPlanned: {
    background: 'rgba(124, 0, 239, 0.5)',
    color: '#ffff',
    border: 'rgba(124, 0, 239, 0.7)',
  },
  paused: {
    background: '#b75b07',
    color: '#fff',
    border: 'rgba(0,0,0,.1)',
  },
  executed: {
    background: '#81AD32',
    color: '#fff',
    border: 'rgba(0,0,0,.1)',
  },
}
