/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { observable, computed, action } from 'mobx'
import { HUB_ROOT } from 'config/app.config'
import * as signalR from '@aspnet/signalr'

export default class Socket {
  @observable connection
  @observable closed = 0
  @observable reconnecting = 0
  @observable mustReconnect = 0

  @computed get isConnected() {
    return this.connection && this.connection.state !== 'Disconnected' && this.closed === 0
  }

  @computed get isReconnecting() {
    return this.closed === 1 && this.reconnecting === 1
  }

  constructor(room, rootStore) {
    this.rootStore = rootStore
    this.room = room

    setInterval(() => {
      console.debug('come to check socket connection')
      if (this.connection) {
        console.debug('socket connection', this.connection)
        if (this.isConnected) {
          console.debug('socket for front is connected')
          console.debug(this)
          if (this.connection.state !== 'Connected' && this.mustReconnect === 1) {
            console.error('not connected in socket')
            this.reconnect()
          }
        }
      }
    }, 10000)
  }

  async connectAsync() {
    console.debug('Connect async was called')
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${HUB_ROOT}/${this.room}`, {
        accessTokenFactory: () => this.rootStore.authStore.accessToken,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build()
    this.connection.serverTimeoutInMilliseconds = 120000
    this.configureHub()

    try {
      await this.connection.start()
      this.connection.onclose(async (error) => {
        console.info('disconnected because an error ', error)
        console.debug(error)
        if (error) await this.reconnect()
      })

      if (this.connectedHandler) this.connectedHandler()
      this.closed = 0
      this.reconnecting = 0
      this.mustReconnect = 1
    } catch (e) {
      throw Error()
    }
  }

  @action.bound async connect() {
    // console.debug('Connection was called with current token', this.rootStore.authStore.accessToken)
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${HUB_ROOT}/${this.room}`, {
        accessTokenFactory: () => this.rootStore.authStore.accessToken,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build()
    this.connection.serverTimeoutInMilliseconds = 120000
    this.configureHub()

    try {
      await this.connection.start()
      this.connection.onclose(async (error) => {
        console.info('disconnected because an error ', error)
        console.debug(error)
        if (error) await this.reconnect()
      })

      if (this.connectedHandler) this.connectedHandler()
      this.closed = 0
      this.reconnecting = 0
      this.mustReconnect = 1
    } catch (e) {
      console.error(e)
      if (e && e.statusCode === 401) {
        console.info(
          'refresh token was called in socket, current token',
          this.rootStore.authStore.accessToken
        )
        await this.rootStore.authStore.refreshAccessToken()
      }
      this.reconnect()
    }
  }

  @action.bound async reconnect() {
    this.closed = 1
    this.reconnecting = 1
    setTimeout(async () => {
      this.connection = null
      await this.connect()
    }, 3000)
  }

  @action async disconnect() {
    this.mustReconnect = 0
    try {
      this.connection.stop().then(() => {
        this.connection = null
      })
    } catch {
      console.error('Socket disconnect was called on null')
    }
  }

  configureHub() {}

  connectionRetryHandler
  onConnectionRetry = (handler) => (this.connectionRetryHandler = handler)
  connectedHandler
  onConnected = (handler) => (this.connectedHandler = handler)
  errorHandler
  onError = (handler) => (this.errorHandler = handler)
}
