import styled, { css } from 'styled-components'

export const MenuOption = styled.li`
  padding: 10px 20px;
  font-family: MontSerrat;
  font-size: 13px;
  color: ${(props) => props.theme.colors.principalDark};
  background-color: ${(props) => props.theme.colors.backgroundContainer};
  padding-right: ${({ paddingRight }) => paddingRight || 20}px;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s ease;
  cursor: pointer;
  min-width: 200px;
  position: relative;
  
  ${({ isDanger }) =>
    isDanger &&
    css`
      color: ${(props) => props.theme.colors.danger} !important;
    `}

  ${({ hasSubMenu }) =>
    hasSubMenu &&
    css`
      &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f105';
        font-weight: bold;
      }
    `}

  &:hover {
    background: ${(props) => props.theme.colors.backgroundContainerHover};
  }
`

export const MenuOptions = styled.ul`
  list-style: none;
  padding: 10px 0px;
  background-color: ${(props) => props.theme.colors.backgroundContainer};
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: ${(props) => (props.isOpen ? 300 : 0)}px;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: scale(${(props) => (props.isOpen ? 1 : 0)});
  transform-origin: bottom right 60px;
  margin-bottom: 10px;
  transition-property: width, all;
  transition-duration: 0s, 0.2s;
  transition-delay: 0s, 0.05s;
  z-index: 1000;

  ${({ direction }) =>
    direction &&
    css`
      transform-origin: ${direction} 60px;
    `}

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      width: 200px;

      ${MenuOption} {
        min-width: 150px;
      }
    `}
`
