import { useEffect, useState } from 'react'
import Speech from 'speak-tts'

export const useSpeech = () => {
  const [speech] = useState(new Speech())
  const [text, setText] = useState('')

  useEffect(() => {
    if (speech.hasBrowserSupport() && speech.lang === 'pt-BR') play()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, speech])

  const play = () => {
    try {
      speech.speak({
        text: text,
        queue: true,
      })
    } catch {
      play()
    }
  }
  const initSpeech = () => {
    try {
      speech.init({
        volume: 0.5,
        lang: 'pt-BR',
        rate: 1,
        pitch: 1,
        splitSentences: true,
      })
    } catch(err) {
      console.log(err)
    }
  }
  useEffect(() => {
    initSpeech()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [setText]
}
