import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const reSyncOrders = async (area) => {
  try {
    await axios.post('integration/workOrder')
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
