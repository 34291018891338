import styled, { css } from 'styled-components'
import { PageTitle } from 'styles/components'
import { MenuOptions, MenuOption } from 'styles/components'
import { Link } from 'react-router-dom'

export const Bar = styled.header`
  background: ${({ theme }) => theme.colors.principalColor};
  background: linear-gradient(90deg, #0f6bff 0, #1f63e3);
  display: inline-grid;
  box-shadow: 2px 2px 6px 4px rgba(0, 0, 0, 0.1);
  padding: 5px 30px;
  grid-auto-flow: column;
  margin-bottom: 5px;
  width: 100%;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  position: relative;

  ${({ isStaging }) =>
    isStaging &&
    css`
      background: linear-gradient(90deg, #673ab7 0px, #c00f4b);
    `}
`

export const OptionsWrapper = styled(MenuOptions)`
  position: absolute;
  top: 70px;
  left: 30px;
  width: 15%;
  z-index: 999;

  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}

  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `}

  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `}

  ${({ right }) =>
    right &&
    css`
      right: ${right}px;
      left: auto;
    `}

  ${({ inline }) =>
    inline &&
    css`
      display: inline-table;
    `}

  ${OptionsBase} {
    ${({ autoWidth }) =>
      autoWidth &&
      css`
        width: 100%;
        max-height: 35vw;
        overflow: auto;
      `}
  }

  ${({ isMobile, isOpen, isSubMenu }) =>
    isMobile &&
    isOpen &&
    !isSubMenu &&
    css`
      display: block !important;
      position: absolute !important;
      height: 97vh;
      align-items: baseline;
    `}

  ${({ isMobile, isOpen, isSubMenu }) =>
    isMobile &&
    isOpen &&
    isSubMenu &&
    css`
      display: flex !important;
      position: initial !important;
    `}

  ${({ isMobile, marginLeft }) =>
    isMobile &&
    marginLeft &&
    css`
      margin-left: -30px;
      margin-top: 10px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: initial;
      width: 100vw;
      display: none;
      flex-wrap: wrap;

      ${OptionsBase} {
        flex: 12 0 100%;
        position: initial;

        $:after {
          content: '\f107' !important;
        }
      }
    `}

  ${({ ordering }) =>
    ordering &&
    css`
      ${OptionsBase} {
        $:after {
          content: '\f338' !important;
          display: block;
        }
      }
    `}

    ${({ width }) =>
    width &&
    css`
      width: ${width}px !important;
    `}
`

export const OptionsBase = styled(MenuOption)`
  min-width: auto;
  justify-content: start;
  color: ${({ theme }) => theme.colors.textColor};

  ${({ hasSubMenu }) =>
    hasSubMenu &&
    css`
      justify-content: space-between;

      &:after {
        display: block;
      }
    `}

  ${({ divisor }) =>
    divisor &&
    css`
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    `}

  ${({ end }) =>
    end &&
    css`
      justify-content: flex-end;
    `}

  ${({ arrowLeft }) =>
    arrowLeft &&
    css`
      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f104';
        margin-right: 15px;
        font-weight: bold;
      }
    `}

  ${({ header }) =>
    header &&
    css`
      font-weight: bold;
      cursor: auto;
    `}

  ${({ isChildren }) =>
    isChildren &&
    css`
      padding-left: 40px;
      &:hover {
        filter: contrast(0.5);
      }
    `}

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${(props) => props.theme.colors.backgroundContainerHover};
    `};

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `};
`

export const Options = styled.div``

export const Logo = styled.div`
  justify-self: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: min-content;
  display: flex;

  img {
    max-width: 30px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;
    `}
`

export const Profile = styled.div`
  display: flex;
  cursor: pointer;
  padding: 5px 10px;
  transition: all 0.4s ease;
  border-radius: 4px;
  justify-self: end;
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
`

export const Label = styled(PageTitle)`
  line-height: 20px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0px 10px 0px 0px;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.principalWhite};

  ${({ tenant }) =>
    tenant &&
    css`
      font-size: 1em;
      font-weight: normal;
    `}

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
