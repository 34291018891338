import React from 'react'

import Menu from '../Menu'

import { UpdateButton } from './styles'
import { withNamespaces } from 'react-i18next'

const Base = ({ children, t }) => {
  return (
    <div id="outer-container">
      <Menu />
      <section className="section-container" id="page-wrap-container">{children}</section>
      <UpdateButton pointer attention primary>
        {t('common.newVersion')}
      </UpdateButton>
    </div>
  )
}

export default withNamespaces()(Base)
