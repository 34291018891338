import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject } from 'mobx-react'

@inject('rootStore')
class DynamicRedirect extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.location !== nextProps.location
  }

  render() {
    let baseLocation
    const { isLoggedIn, roles } = this.props.rootStore.authStore
    if (!isLoggedIn) baseLocation = '/login'
    else if (roles && ['workStation', 'superuser'].some((r) => roles.includes(r)))
      baseLocation = '/workStation'
    else if (roles && ['planning', 'superuser'].some((r) => roles.includes(r)))
      baseLocation = '/planning'
    else baseLocation = '/'

    return (
      <Route
        exact
        from="/"
        render={(props) => {
          return <Redirect to={baseLocation} />
        }}
      />
    )
  }
}

export default DynamicRedirect
