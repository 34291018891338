import styled, { css } from 'styled-components'
import ReactImageFallback from 'react-image-fallback'

export const CircleImage = styled(ReactImageFallback)`
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
  
  ${({ min }) =>
    min &&
    css`
      min-height: ${min}px;
      min-width: ${min}px;
    `}

  ${({ maxSize }) =>
    maxSize &&
    css`
      height: ${maxSize}px;
      width: ${maxSize}px;
    `}
`
