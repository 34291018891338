import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAll = async () => {
  try {
    const { data: pauses } = await axios.get('tag')
    return pauses
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (formData) => {
  try {
    await axios.put(`tag/${formData.id}`, formData)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllByProcess = async (processId) => {
  try {
    const { data: response } = await axios.get(`tag/by-process/${processId}`)
    return response
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const activate = async (id) => {
  try {
    return await axios.post(`tag/${id}/activate`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const deactivate = async (id) => {
  try {
    return await axios.post(`tag/${id}/deactivate`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (formData, pause) => {
  try {
    const response = await axios.post('tag', formData)
    const { data } = response
    return data
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
