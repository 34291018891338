import { useEffect, useState } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

export const useRecorderMedia = () => {
  const [audioURL, setAudioURL] = useState('')
  const [blobFile, setBlobFile] = useState(undefined)
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const { transcript, resetTranscript } = useSpeechRecognition()

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then((record) => {
          console.info('Recorder was generated')
          setRecorder(record)
        }, console.error)
      }
      return
    }

    if (isRecording && recorder) {
      recorder
        .start()
        .then(() => {
          console.info('Recording started')
          if (SpeechRecognition.browserSupportsSpeechRecognition()) {
            console.info('Speech recognition was supported')
            SpeechRecognition.startListening({ language: 'pt-br', continuous: true })
          }
        })
        .catch((e) => {
          console.error(e)
        })
    } else if (recorder) {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          console.info('Recording stopped')
          if (SpeechRecognition.browserSupportsSpeechRecognition()) {
            console.info('Speech recognition was supported')
            SpeechRecognition.stopListening()
          }
          const file = new File(buffer, 'audio-recorder.mp3', {
            type: blob.type,
            lastModified: Date.now(),
          })

          setAudioURL(URL.createObjectURL(file))
          setBlobFile(file)
          console.info('Mp3 file was generated')
          console.info('Recognition result was: ' + transcript)
          console.info(file)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder, isRecording])

  const startRecording = () => {
    setIsRecording(true)
    if (SpeechRecognition.browserSupportsSpeechRecognition()) resetTranscript()
  }

  const stopRecording = () => {
    setIsRecording(false)
  }

  return [audioURL, blobFile, transcript, isRecording, startRecording, stopRecording]
}

async function requestRecorder() {
  return new Promise(async (resolve, reject) => {
    await navigator.getUserMedia(
      { audio: true },
      () => {
        resolve(
          new MicRecorder({
            bitRate: 128,
          })
        )
      },
      () => {
        console.error('Permission was denied by user')
        throw new Error('Permission was denied by user')
      }
    )
  })
}
