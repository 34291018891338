import React, { useEffect, useState, useCallback } from 'react'
import Menus from 'Menu'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import { reaction } from 'mobx'
import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { PieChartOutlined } from '@ant-design/icons'
import { Menu as AntdMenu } from 'antd'
import { slide as Menu } from 'react-burger-menu'
import { IconButton } from 'styles/components'

const MobileButtonMenu = observer(({ rootStore, t, history }) => {
  const { selectedTenant } = rootStore.authStore

  reaction(
    () => rootStore.areaStore.areas.slice(),
    () => loadMenu()
  )

  reaction(
    () => rootStore.workStationStore.workStations.slice(),
    () => loadMenu()
  )

  reaction(
    () => rootStore.authStore.roles.slice(),
    () => loadMenu()
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadMenu(), [])

  const loadMenu = useCallback(() => {
    madeWorkStationSubMenus()
    madeMenu()
  }, [madeWorkStationSubMenus, madeMenu])

  const [menu, setMenu] = useState(
    localStorage.getItem(selectedTenant + '-menuOptions')
      ? JSON.parse(localStorage.getItem(selectedTenant + '-menuOptions'))
      : []
  )
  const { areasMenu } = rootStore.areaStore

  const madeWorkStationSubMenus = useCallback(() => {
    const { areas } = rootStore.areaStore
    const { workStations } = rootStore.workStationStore

    const workStationsByAreaId = _.chain(workStations)
      .orderBy(['shortName'])
      .groupBy('areaId')
      .value()

    let haveUserOrdering = areasMenu.length !== 0
    let lastUserOrdering = !haveUserOrdering
      ? 0
      : areasMenu.sort((a, b) => b.order - a.order)[0].order

    const menu = _.chain(areas)
      .orderBy(['description'])
      .map((area) => {
        const workStationsGroup = workStationsByAreaId[area.id]
        if (workStationsGroup) {
          const areaOrdering = areasMenu.find((aM) => aM.id === area.id)

          const currentOrder =
            !haveUserOrdering || !areaOrdering ? ++lastUserOrdering : areaOrdering.order

          let lastUserWorkStationOrdering = !areaOrdering
            ? 0
            : areaOrdering.subItems.sort((a, b) => b.order - a.order)[0].order

          return {
            name: area.description,
            order: currentOrder,
            id: area.id,
            subItems: workStationsGroup.map((workStation) => {
              const workStationOrdering = areaOrdering?.subItems?.find(
                (w) => w.subPath === `${workStation.id}`
              )

              const currentWorkStationOrder =
                !haveUserOrdering || !workStationOrdering
                  ? ++lastUserWorkStationOrdering
                  : workStationOrdering.order

              return {
                subName: workStation.shortName,
                order: currentWorkStationOrder,
                subPath: workStation.id.toString(),
              }
            }),
          }
        }

        return null
      })
      .filter((menuItem) => menuItem !== null)
      .value()

    rootStore.areaStore.updateAreaOrdering(menu)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootStore])

  const filterRoles = useCallback(
    (menu, outerAllowedRoles) => {
      const { roles } = rootStore.authStore

      if (menu.subMenu?.length > 0) {
        menu.subMenu = menu.subMenu.filter((subMenuItem) =>
          filterRoles(subMenuItem, menu.allowedRoles)
        )
        return menu.subMenu.length > 0
      } else {
        if (!menu.allowedRoles) {
          if (!outerAllowedRoles) return true
          return roles.some((r) => outerAllowedRoles.includes(r))
        }

        return roles.some((r) => menu.allowedRoles.includes(r))
      }
    },
    [rootStore]
  )

  const madeMenu = useCallback(() => {
    let updatedMenu = JSON.parse(JSON.stringify(Menus))
    updatedMenu = updatedMenu.filter((menuItem) => filterRoles(menuItem))
    if (menu.length > 0) {
      updatedMenu = updatedMenu.map((u) => {
        const menuItem = menu.find((m) => m.path === u.path)
        if (menuItem) u.order = menuItem.order
        return u
      })
      updatedMenu = updatedMenu.sort((a, b) => a.order - b.order)
    }
    setMenu(updatedMenu)
  }, [filterRoles, menu])

  useEffect(() => {
    localStorage.setItem(selectedTenant + '-menuOptions', JSON.stringify(menu))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  const onClick = (e) => {
    history.push(e.key)
  }

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    }
  }

  const items = menu
    ?.filter((menu) => !menu.heading)
    .sort((a, b) => a.order - b.order)
    .map((menu) => {
      return getItem(
        t(menu.translate),
        '/' + menu.path,
        <IconButton className={menu.icon} pointer marginRight={10} />,
        menu.subMenu?.concat(menu.hasActivities ? areasMenu : [])?.map((subMenu) => {
          return getItem(
            subMenu.translate ? t(subMenu.translate) : subMenu.name,
            `/${menu.path}/${subMenu.path ?? subMenu.id}`,
            undefined,
            subMenu.subItems?.map((subItem) => {
              return getItem(subItem.subName, `/${menu.path}/${subItem.subPath}`, undefined)
            })
          )
        })
      )
    })

  return (
    <Menu pageWrapId={'page-wrap-container'} outerContainerId={'outer-container'}>
      <AntdMenu
        onClick={onClick}
        style={{ width: 256, boxShadow: 'none', borderRight: 'none' }}
        defaultOpenKeys={['/workstation']}
        mode="inline"
        items={items ?? []}
      />
    </Menu>
  )
})

export default withNamespaces()(withRouter(inject('rootStore')(MobileButtonMenu)))
