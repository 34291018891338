import * as signalR from '@aspnet/signalr'
import { HUB_ROOT } from 'config/app.config'
import { Backoff, FibonacciStrategy } from 'backoff'
import { observable } from 'mobx'

export default class baseHub {
  @observable connection = undefined
  @observable stayConnected = false
  @observable connectionOpen = false

  constructor(room, rootStore) {
    this.rootStore = rootStore
    this.room = room
    this.reconnect = true

    const fibonacciStrategy = new FibonacciStrategy({
      randomisationFactor: 0.1,
      initialDelay: 200,
      maxDelay: 2000,
    })

    this.connectionRetryBackoff = new Backoff(fibonacciStrategy)

    this.connectionRetryBackoff.on('backoff', async (number, delay, error) => {
      this.backOffInProgress = true
      if (!this.connection) await this.beginConnection()
      this.backOffInProgress = false
    })

    this.connectionRetryBackoff.on('ready', (number, delay) => {
      if (this.reconnect) this.connectionRetryBackoff.backoff()
      if (this.connectionRetryHandler && number > 0) {
        try {
          this.connectionRetryHandler(number)
        } catch (connectedHandlerError) {
          console.error("Error on 'onConnectionRetry' handler", connectedHandlerError)
        }
      }
    })
  }

  async beginConnection() {
    await this.closePastConnections()
    this.stayConnected = true
    await this.ensureConnection()
  }

  async closePastConnections() {
    if (this.closeInProgress) {
      Promise.resolve(this.closeInProgress)
      this.closeInProgress = undefined
    }

    return this.closeConnection()
  }

  async ensureConnection() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${HUB_ROOT}/${this.room}`, {
        accessTokenFactory: () => this.rootStore.authStore.accessToken,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    this.connection.serverTimeoutInMilliseconds = 120000

    this.configureHub()

    return await this.startConnection()
  }

  configureHub() {
    this.connection.onclose(async (error) => {
      this.connection = null
      this.connectionOpen = false
      if (error && this.errorHandler) {
        try {
          await this.errorHandler(error)
        } catch (connectedHandlerError) {
          console.error("Error on 'onError' handler", connectedHandlerError)
        }
      }

      if (this.reconnect) this.connectionRetryBackoff.backoff()
    })
  }

  startConnection() {
    if (this.startInProgress) return this.startInProgress
    this.startInProgress = (async () => {
      try {
        await this.connection.start()
        this.connectionRetryBackoff.reset()
        this.connectionOpen = true

        try {
          if (this.connectedHandler) this.connectedHandler()
        } catch {}
      } catch (error) {
        if (error && error.statusCode === 401 && this.rootStore.authStore.accessTokenExpired) {
          await this.rootStore.authStore.refreshAccessToken()

          this.connectionOpen = false
          this.connection = undefined

          try {
            this.connectionRetryBackoff.backoff()
          } catch {}
        } else {
          await this.closeConnection()
        }
      } finally {
        this.reconnect = true
        this.startInProgress = undefined
      }
    })()
  }

  closeConnection() {
    this.stayConnected = false
    this.closeInProgress = (async () => {
      try {
        this.reconnect = false
        if (this.connection) await this.connection.stop()
      } finally {
        this.connection = undefined
        this.closeInProgress = undefined
      }
    })()

    return this.closeInProgress
  }

  /* overrided functions */
  connectionRetryHandler
  onConnectionRetry = (handler) => (this.connectionRetryHandler = handler)
  connectedHandler
  onConnected = (handler) => (this.connectedHandler = handler)
  errorHandler
  onError = (handler) => (this.errorHandler = handler)
}
