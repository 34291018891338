import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getWorkOrderReport = async (workOrderId) => {
  try {
    const { data: workOrderReport } = await axios.get(
      `report/workOrderFullReport?workOrderId=${workOrderId}`
    )
    return workOrderReport
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
