import styled, { css } from 'styled-components'
import { Text } from './index'
import { Rotate } from '../keyframes/animations'

const BaseButton = styled.button`
  user-select: none;
  transition: all 0.2s ease;
  border-radius: 4px;
  font-size: 26px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  width: 100%;
  height: 48px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.principalWhite};
  background-color: ${(props) => props.theme.colors.principalColor};
  border-color: ${(props) => props.theme.colors.principalColor};

  &:disabled {
    cursor: initial;
    opacity: 0.65;
  }

  &:disabled:hover {
    opacity: 0.82;
  }

  &:not(:disabled):hover {
    background-color: ${(props) => props.theme.colors.principalColorHover};
    border-color: ${(props) => props.theme.colors.principalColorHover};
  }
`

export const RetangularButton = styled(BaseButton)`
  height: 70px;
  background-color: ${(props) => props.color || props.theme.colors.principalColor};
  border-color: ${(props) => props.color || props.theme.colors.principalColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  padding: 2px;

  ${({ grey }) =>
    grey &&
    css`
      background: hsl(0, 0%, 90%);
      color: #767676 !important;
      border-color: hsl(0, 0%, 90%);
      border: none;
    `}

  &:not(:disabled):hover {
    opacity: 0.7;
    background-color: ${(props) => props.color || props.theme.colors.principalColor};
    border-color: ${(props) => props.color || props.theme.colors.principalColor};
  }
`

export const PrincipalButton = styled(BaseButton)`
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  font-size: 20px;
`

export const BlueButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.primary};
  border-color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  font-size: 1.2em;
  height: 40px;

  &:not(:disabled):hover {
    opacity: 0.9;
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
`

export const FloattingButton = styled(BlueButton)`
  border-radius: 20px;
  padding: 0px 15px;
  margin-left: 5px;
  transition: all 0.2s ease;
  width: auto;
  background: #2c2c2c;
  border-color: #2c2c2c;

  &:after {
    font-size: 20px;
    font-weight: bold;
    transition: all 0.2s ease;
    content: '${(props) => (!props.isOpen ? '?' : 'x')}';
  }

  &:not(:disabled):hover {
    opacity: 0.9;
    background-color: #2c2c2c;
    border-color: #2c2c2c;
    &:after {
      font-size: 23px;
    }
  }
`

export const OutlineButton = styled(Text)`
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-out;
  font-family: 'Poppins';
  font-weight: bold;
  color: #575757 !important;
  background: #e2e2e2;
  box-shadow: inset 0px -5px 0px 0px rgba(0, 0, 0, 0.2);
  margin-right: ${({ marginRight }) => marginRight || 0}px !important;
  margin-left: ${({ marginLeft }) => marginLeft || 10}px !important;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px !important;
  margin-top: ${({ marginTop }) => marginTop || 0}px !important;
  user-select: none;

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    transform: scale(0.98);
  }

  ${({ selected }) =>
    selected &&
    css`
      filter: brightness(1.2);
    `}

  ${({ attention, primary, principal, theme }) =>
    attention &&
    css`
      border: 1px solid;
      background: ${primary
        ? theme.colors.primary
        : principal
        ? theme.colors.principalColor
        : theme.colors.success};
      color: #fff !important;
      box-shadow: inset 0px -5px 0px 0px rgba(0, 0, 0, 0.2);
    `}

  ${({ attention, danger, theme }) =>
    attention &&
    danger &&
    css`
      border: 1px solid;
      background: ${theme.colors.danger};
      color: #fff !important;
    `}
  
    ${({ attention, warning, theme }) =>
    attention &&
    warning &&
    css`
      border: 1px solid;
      background: ${theme.colors.warning};
      color: #fff !important;
    `}

  ${({ attention, secondary, theme }) =>
    attention &&
    secondary &&
    css`
      border: 1px solid;
      background: #2671ff;
      color: #fff !important;
    `}

  ${({ submitted, theme }) =>
    submitted &&
    css`
      border: 1px solid;
      background: ${theme.colors.success} !important;
      color: #fff !important;
    `}
    
  ${({ loading }) =>
    loading &&
    css`
      &:after {
        content: '';
        height: 25px;
        width: 25px;
        border-radius: 50%;
        justify-self: flex-start;
        display: table;
        border-left: 1px solid #fafafa;
        border-top: 1px solid #fafafa
        border-bottom: 1px solid #c0c0c0;
        border-right: 1px solid #c0c0c0;
        margin-left: 10px;
        animation: ${Rotate} 1s cubic-bezier(0.54, 0.23, 0.57, 0.93) infinite;
      }
    `}
`

export const IconButton = styled.i`
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || 1.2}em;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}

  &:hover {
    transform: scale(1.4);
  }

  &:active {
    transform: scale(0.9);
  }

  ${({ light }) =>
    light &&
    css`
      color: ${({ theme }) => theme.colors.principalWhite} !important;
    `}

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer !important;
    `}
    
  ${({ warning }) =>
    warning &&
    css`
      color: ${({ theme }) => theme.colors.warning} !important;
    `}

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.colors.danger} !important;
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${({ theme }) => theme.colors.success} !important;
    `}

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary} !important;
    `}
`
