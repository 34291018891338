import React from 'react'

import 'loaders.css/loaders.css'
import ContentWrapper from '../../Layout/ContentWrapper'

const divStyleParent = {
  border: '2px solid #3B86FF',
  borderTopColor: 'transparent',
  borderBottomColor: 'transparent',
}
const divStyleChild = {
  borderColor: '#3B86FF',
}

const spinner = () => (
  <ContentWrapper>
    <div className="card-body loader-demo d-flex align-items-center justify-content-center">
      <div className="ball-clip-rotate-multiple">
        <div style={divStyleParent}></div>
        <div style={divStyleChild}></div>
      </div>
    </div>
  </ContentWrapper>
)

export default spinner
