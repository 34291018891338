import React, { memo } from 'react'
import QRCode from 'qrcode.react'
import { withTheme } from 'styled-components'

const EmployeeQrCode = memo(({ registration, error, theme }) => {
  return (
    <>
      {registration && registration.length > 0 && (
        <QRCode
          value={registration}
          size={80}
          fgColor={error ? theme.colors.danger : theme.colors.principalColor}
        />
      )}
    </>
  )
})

export default withTheme(EmployeeQrCode)
