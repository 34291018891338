import React, { useState, useEffect } from 'react'
import PrimaryInput from 'components/PrimaryInput'
import PrimarySelect from 'components/PrimarySelect'
import PrimaryChips from 'components/PrimaryChips'
import { FlexColumn, FlexContainer, BaseCheckBox } from 'styles/components'
import { durationToString } from 'utils/durationString'
import EmployeeContext from './context'
import EmployeeQrCode from './employeeQrCode'
import { withNamespaces } from 'react-i18next'
import { Subtitle } from 'styles/components/page'
import UploadPhotoContainerWrapper from './uploadPhotoContainer'
import { EmployeeNotifications } from './employeeNotifications'

const EmployeeForm = ({ employee, updateEmployee, t, waitId, cannotEditEmail = false }) => {
  const [updatedEmployee, setUpdatedEmployee] = useState(employee)

  const handleChange = ({ currentTarget: input }) => {
    setUpdatedEmployee((prev) => ({ ...prev, [input.name]: input.value }))
  }

  const handleChangeUser = ({ currentTarget: input }) => {
    setUpdatedEmployee((prev) => ({ ...prev, user: { ...prev.user, [input.name]: input.value } }))
  }

  const handleSelect = (name, option) => {
    setUpdatedEmployee((prev) => ({ ...prev, [name]: option }))
  }

  const handleChangePhoto = (photo) => setUpdatedEmployee((prev) => ({ ...prev, photo }))

  const handleSelectRoles = (role) => {
    let { user } = updatedEmployee
    let { roles } = user
    if (!roles) roles = []
    if (roles.some((r) => r.id === role.value)) roles = roles.filter((r) => r.id !== role.value)
    else roles = [...roles, { id: role.value }]
    user = { ...user, roles }
    setUpdatedEmployee((prev) => ({ ...prev, user }))
  }

  useEffect(() => {
    if (updatedEmployee !== employee) updateEmployee(updatedEmployee)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedEmployee, updateEmployee])

  useEffect(() => {
    setUpdatedEmployee(employee)
  }, [employee])

  return (
    <EmployeeContext.Consumer>
      {({ shifts, employees, areas, roles, workStations, actions }) => {
        const shiftOptions = shifts.map((s) => {
          const startTime = durationToString(s.startTime)
          const endTime = durationToString(s.endTime)
          const shiftLabel = `${s.description} (${startTime} - ${endTime})`

          return {
            value: s.id,
            label: shiftLabel,
          }
        })

        const areasOptions = areas.map((a) => ({
          value: a.id,
          label: a.description,
        }))

        const supervisorOptions = employees.map((em) => ({
          value: em.id,
          label: em.fullName,
        }))

        const rolesOptions = roles.map((r) => ({
          value: r.id,
          label: r.name,
        }))

        const registrationAlreadyExists = actions.validateRegistration(
          updatedEmployee.registration,
          updatedEmployee.id
        )

        const keyIdsValues = {
          2: 'Administrador',
          3: 'Planejamento (PCP)',
          4: 'Operação',
          5: 'Tempo Real',
          6: 'Engenharia',
        }

        const translateValues = rolesOptions.map((r) => ({
          value: r.value,
          label: keyIdsValues[r.value],
        }))

        return (
          <>
            <FlexContainer marginBottom={20}>
              <FlexColumn direction={'row'} align={'normal'}>
                <FlexColumn flex={2} justify={'space-evenly'} align={'center'} smHidden mdHidden>
                  <FlexColumn>
                    <UploadPhotoContainerWrapper
                      key={employee.id}
                      employee={updatedEmployee}
                      waitId={waitId}
                      photo={updatedEmployee.photo}
                      handleChangePhoto={handleChangePhoto}
                    />
                  </FlexColumn>
                  <FlexColumn>
                    <EmployeeQrCode
                      key={updatedEmployee.sequence}
                      registration={updatedEmployee.registration}
                      error={registrationAlreadyExists}
                    />
                  </FlexColumn>
                </FlexColumn>
                <FlexColumn flex={10} align={'initial'}>
                  <FlexColumn direction={'row'} align={'flex-end'}>
                    <FlexColumn flex={6} align={'normal'}>
                      <PrimaryInput
                        label={t('setup.employee.form.completeName')}
                        name="fullName"
                        placeholder="Ex. João Ricardo Moreira Silva"
                        type="text"
                        required
                        minLength={2}
                        value={updatedEmployee.fullName}
                        onChange={handleChange}
                      />
                    </FlexColumn>
                    <FlexColumn flex={4} align={'normal'} marginLeft={10}>
                      <PrimaryInput
                        label={t('setup.employee.form.smallName')}
                        name="shortName"
                        placeholder="Ex. João Silva"
                        type="text"
                        required
                        minLength={2}
                        value={updatedEmployee.shortName}
                        onChange={handleChange}
                      />
                    </FlexColumn>
                  </FlexColumn>
                  <FlexColumn direction={'row'} align={'flex-end'}>
                    <FlexColumn flex={2} align={'normal'}>
                      <PrimaryInput
                        label={t('setup.employee.form.cellphone')}
                        name="cellphone"
                        placeholder="Ex. 11 9 8822-5599"
                        mask={'(99) 9 9999-9999'}
                        type="text"
                        value={updatedEmployee.cellphone}
                        onChange={handleChange}
                      />
                    </FlexColumn>
                    <FlexColumn flex={2} align={'normal'} marginLeft={10}>
                      <PrimaryInput
                        label={t('setup.employee.form.function')}
                        name="department"
                        placeholder="Ex. Produção"
                        type="text"
                        required
                        value={updatedEmployee.department}
                        onChange={handleChange}
                      />
                    </FlexColumn>
                    <FlexColumn flex={2} align={'normal'} marginLeft={10}>
                      <PrimaryInput
                        label={t('setup.employee.form.registration')}
                        name="registration"
                        placeholder="PS212555386"
                        type="text"
                        required
                        error={registrationAlreadyExists}
                        errorMessage={t('setup.employee.form.registrationAllReadyExists', {
                          name:
                            registrationAlreadyExists?.shortName ??
                            registrationAlreadyExists?.fullName,
                        })}
                        value={updatedEmployee.registration}
                        onChange={handleChange}
                      />
                    </FlexColumn>
                  </FlexColumn>
                  <FlexColumn direction={'row'} align={'flex-end'}>
                    <FlexColumn flex={2} align={'normal'}>
                      <PrimarySelect
                        label={t('setup.employee.form.supervisor')}
                        name="supervisorId"
                        placeholder="Ex: Alan Dias"
                        options={supervisorOptions}
                        value={
                          updatedEmployee.supervisorId &&
                          supervisorOptions.find((sp) => sp.value === updatedEmployee.supervisorId)
                        }
                        onChange={(option) => handleSelect('supervisorId', option.value)}
                      />
                    </FlexColumn>
                    <FlexColumn flex={2} align={'normal'} marginLeft={10}>
                      <PrimarySelect
                        label={t('setup.employee.form.workStation')}
                        name="areaId"
                        placeholder="Ex: Produção"
                        options={areasOptions}
                        required
                        value={
                          updatedEmployee.areaId &&
                          areasOptions.find((area) => area.value === updatedEmployee.areaId)
                        }
                        onChange={(option) => handleSelect('areaId', option.value)}
                      />
                    </FlexColumn>
                    <FlexColumn flex={2} align={'normal'} marginLeft={10}>
                      <PrimarySelect
                        label={t('setup.employee.form.shift')}
                        name="shiftId"
                        placeholder="Ex: manhã"
                        options={shiftOptions}
                        required
                        value={
                          updatedEmployee.shiftId &&
                          shiftOptions.find((s) => s.value === updatedEmployee.shiftId)
                        }
                        onChange={(option) => handleSelect('shiftId', option.value)}
                      />
                    </FlexColumn>
                  </FlexColumn>
                </FlexColumn>
              </FlexColumn>
              <FlexColumn
                direction={'row'}
                align="center"
                marginTop={10}
                onClick={() => handleSelect('isOperator', !updatedEmployee.isOperator)}
              >
                <BaseCheckBox checked={updatedEmployee.isOperator} />
                <Subtitle noMargin>Você é operador em algum centro de trabalho?</Subtitle>
              </FlexColumn>
              {updatedEmployee.hasUser && (
                <>
                  <FlexColumn align={'normal'}>
                    <FlexColumn direction={'row'} marginTop={20}>
                      <FlexColumn flex={3} align={'normal'}>
                        <PrimaryInput
                          label={t('setup.employee.form.email')}
                          name="email"
                          placeholder="marcelo@plantscanner.io"
                          type="email"
                          subTitle={'Este é seu email de acesso ao PlantScanner'}
                          disabled
                          required
                          value={updatedEmployee.user?.email}
                        />
                      </FlexColumn>
                      <FlexColumn flex={3} />
                    </FlexColumn>
                    <FlexColumn marginTop={10} align={'normal'} style={{ display: 'none' }}>
                      <PrimaryChips
                        availableOptions={translateValues}
                        onChange={handleSelectRoles}
                        selectedValues={
                          updatedEmployee.user &&
                          updatedEmployee.user.roles &&
                          rolesOptions.filter((r) =>
                            updatedEmployee.user?.roles.find((role) => r.value === role.id)
                          )
                        }
                        label={t('setup.employee.form.permissions')}
                      />
                    </FlexColumn>
                    <FlexColumn marginTop={10} align={'normal'}>
                      <EmployeeNotifications
                        updatedEmployee={updatedEmployee}
                        areas={areas}
                        workStations={workStations}
                        setUpdatedEmployee={setUpdatedEmployee}
                      />
                    </FlexColumn>
                  </FlexColumn>
                </>
              )}
            </FlexContainer>
          </>
        )
      }}
    </EmployeeContext.Consumer>
  )
}

export default withNamespaces()(EmployeeForm)
