import axios from 'axios'
import { API_ROOT } from './app.config'
import stores from '../stores'

class AxiosConfig {
  constructor() {
    const { authStore } = stores

    axios.defaults.baseURL = API_ROOT
    axios.interceptors.request.use(
      async (config) => {
        if (authStore.accessTokenExpired) await authStore.refreshAccessToken()

        const token = authStore.accessToken

        config.headers.Authorization = token && `Bearer ${token}`

        config.headers.HostName = window.location.origin

        return config
      },
      (error) => Promise.reject(error)
    )
  }
}

export default new AxiosConfig()
