import React, { useState, useRef, memo } from 'react'
import { employeeService } from 'services'

import { UploadPhotoContainer } from './styles'

import { CircleImage, HiddenInput, UserLetter } from 'styles/components'
import { CircularLoader } from 'styles/components/page'
import { withNamespaces } from 'react-i18next'

import { BLOB_STORAGE } from 'config/app.config'
import UserSvg from 'images/svgs/userIcon.svg'
import { inject } from 'mobx-react'
import EmployeeContext from './context'

const UploadPhotoContainerWrapper = memo(
  ({ rootStore, employee, t, waitId, photo, handleChangePhoto, canEdit = true }) => {
    const { notify } = rootStore.toastMiddleware
    const uploadInput = useRef(null)
    const imageRef = useRef()
    const [uploading, setUploading] = useState(false)
    const [updatedCount, setUpdatedCount] = useState(0)
    const { selectedTenant } = rootStore.authStore
    
    const handleUpload = async (e) => {
      const fileUploaded = e.target.files[0]

      if (!fileUploaded) return

      if (!/\.(jpe?g|png)$/i.test(fileUploaded.name))
        return notify(t('common.onlyFiles', { ext: '.png e .jpeg' }), {
          type: 'error',
          position: 'bottom-right',
        })

      if (!waitId && employee.id) {
        setUploading(true)
        const url = await employeeService.uploadPhoto(fileUploaded, selectedTenant, employee.id)
        imageRef.current.src = imageRef.current.props.src + '?' + Date.now()
        if (url) setUpdatedCount(updatedCount + 1)
        setUploading(false)
      } else {
        let reader = new FileReader()
        reader.onloadend = () => {
          handleChangePhoto({ file: fileUploaded, preview: reader.result })
        }

        reader.readAsDataURL(fileUploaded)
      }
    }

    return (
      <EmployeeContext.Consumer>
        {({ actions }) => (
          <UploadPhotoContainer
            canEdit={canEdit}
            onClick={() => canEdit && uploadInput.current.click()}
          >
            {uploading && <CircularLoader />}
            {waitId && !employee.id && (
              <CircleImage
                src={photo.preview}
                fallbackImage={UserSvg}
                ref={imageRef}
                alt={employee.shortName}
              />
            )}
            {!waitId && (
              <CircleImage
                src={`${BLOB_STORAGE}/${selectedTenant}/employee-${employee.id}.jpg?${updatedCount}${new Date().getMinutes()}`}
                fallbackImage={
                  <UserLetter
                    key={employee.id}
                    letter={employee.shortName[0] ?? employee.fullName[0]}
                  />
                }
                initialImage={<UserLetter key={employee.id} letter={'...'} />}
                ref={imageRef}
                // onError={(e) => }
                alt={employee.shortName}
              />
            )}
            <HiddenInput
              type="file"
              ref={uploadInput}
              inputProps={{ accept: 'image/*' }}
              onChange={handleUpload}
            />
          </UploadPhotoContainer>
        )}
      </EmployeeContext.Consumer>
    )
  }
)

export default withNamespaces()(inject('rootStore')(UploadPhotoContainerWrapper))
