import ReactGA from 'react-ga'

export const firebaseConfig = {
  apiKey: 'AIzaSyCuszialwLMnP5KzzuPrN5Y7yOqwj3eIZs',
  authDomain: 'plantscanner-a90e7.firebaseapp.com',
  databaseURL: 'https://plantscanner-a90e7.firebaseio.com',
  projectId: 'plantscanner-a90e7',
  storageBucket: 'plantscanner-a90e7.appspot.com',
  messagingSenderId: '792025737026',
  appId: '1:792025737026:web:8631ccf72a77e5c69c0adb',
  measurementId: 'G-1DCQLTNJX7',
}

export const trackingId = 'UA-142472477-2'

export const eventTrack = (category, action, label, name) => {
  ReactGA.event({
    category: category,
    action: action,
    label: localStorage.getItem('user-tenant') + '/' + label,
  })
}
