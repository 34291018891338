import styled, { css } from 'styled-components'
import { OutlineButton } from 'styles/components'

export const UpdateButton = styled(OutlineButton)`
  max-width: fit-content;
  white-space: break-spaces;
  position: fixed;
  bottom: -10%;
  left: 2%;
  z-index: 150;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  text-align: center;
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: none;
  ${({ show }) =>
    show &&
    css`
      transform: translateY(-130%);
      bottom: -3%;
    `}
`
