export default {
  name: 'light',
  colors: {
    principalWhite: '#fff',
    principalDark: '#000',
    principalColor: '#3B86FF',
    principalColorHover: '#2671FF',
    inputFocusColor: '#3B86FF',
    inputColorError: '#ED3333',
    textColor: '#2B2B2B',
    black: '#333333',
    backgroundDefault: '#f3f3f3',
    backgroundDefaultTile: '#f3f3f3',
    backgroundContainer: '#fafafa',
    backgroundContainerHover: ' #efefef',
    baseInputColor: '#E1E1E1',
    baseInputBorderColor: '#ABABAB',
    primary: '#133A78', //133A78
    success: '#81AD32',
    warning: '#ffae42',
    danger: '#db2828',
    gray: '#a5a5a5',
    userTileColors: ['#2671FF', '#FFBF66', '#615499', '#2E0ACC', '#CC5F0A'],
  },
}
