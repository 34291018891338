import { keyframes } from 'styled-components'

export const Pulse = keyframes`
    0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`

export const Rotate = keyframes`
		0% { transform: rotate(0deg); }
    100% { transform:rotate(360deg); }
`

export const LinearSpin = keyframes`
		0% { left: 0px; }
    50% { left: 90%; }
    100% { left: 0%; }
`

export const Disappear = keyframes`
	0% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.4;
	}
	100% {
		opacity: 0.7;
	}
`

export const AnimatedGradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
`

export const Appear = keyframes`
	0% {
		display: none;
    opacity: 0;
	}
	1% {
		display: flex;
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`
