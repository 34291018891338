import styled, { css } from 'styled-components'
import { Modal } from 'reactstrap'

export const Header = styled.div`
  padding-bottom: 10px;
  padding-top: 30px;
`
export const Footer = styled.div`
  padding-bottom: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  align-items: center;
`

export const Body = styled.div`
  padding: 2%;
`

export const ModalWrapper = styled(Modal)`
  ${Body} {
    ${({ padding }) =>
      padding &&
      css`
        padding: ${padding}px;
      `}
  }
`
