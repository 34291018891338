/* eslint-disable max-len */
import axios from 'axios'
import { extractError } from '../utils/errorHandler'
import { API_SERVER_RETENTION } from '../../config/app.config'

export const getById = async (id) => {
  try {
    const { data: templateById } = await axios.get(`processExecutionTemplate/${id}`, {
      baseURL: API_SERVER_RETENTION,
    })
    return templateById
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const drop = async (id) => {
  try {
    await axios.delete(`processExecutionTemplate/${id}`, {
      baseURL: API_SERVER_RETENTION,
    }) 
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getInfo = async () => {
  try {
    const { data: info } = await axios.get('processExecutionTemplate/info', {
      baseURL: API_SERVER_RETENTION,
    })
    return info
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAll = async ({ currentItem, perPage, query }) => {
  try {
    const { data: templates } = await axios.get('processExecutionTemplate', {
      params: { currentItem, perPage, orderByAsc: query },
      baseURL: API_SERVER_RETENTION,
    })
    return templates
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (template) => {
  try {
    const updatedSequences = template.sequences.map((s) => {
      s.sequence = parseInt(s.sequence)
      return s
    })
    template.sequences = updatedSequences
    const { data: response } = await axios.put('processExecutionTemplate', template, {
      baseURL: API_SERVER_RETENTION,
    })
    const { status: statusSocket } = await axios.post(
      'processExecutionTemplate/updateSocket',
      template
    )
    console.info(`Process socket updated with status code: ${statusSocket}`)
    return response
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const updateProcess = async (template) => {
  try {
    const updatedSequences = template.sequences.map((s) => {
      s.sequence = parseInt(s.sequence)
      return s
    })
    template.sequences = updatedSequences
    const { data: response } = await axios.put('processExecutionTemplate/process', template, {
      baseURL: API_SERVER_RETENTION,
    })
    const { status: statusSocket } = await axios.post(
      'processExecutionTemplate/updateSocket',
      template
    )
    console.info(`Process socket updated with status code: ${statusSocket}`)
    return response
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (template) => {
  try {
    const updatedSequences = template.sequences.map((s) => {
      s.sequence = parseInt(s.sequence)
      return s
    })
    template.sequences = updatedSequences
    const { data: response } = await axios.post('processExecutionTemplate', template, {
      baseURL: API_SERVER_RETENTION,
    })

    const { status: statusSocket } = await axios.post(
      'processExecutionTemplate/updateSocket',
      template
    )
    console.info(`Process socket updated with status code: ${statusSocket}`)
    return response
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
