/* eslint-disable no-useless-escape */
export const validateEmployee = (employee) => {
  // eslint-disable-next-line max-len
  let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!employee.department || employee.department.length < 2) return false
  if (!employee.fullName || employee.fullName.length < 2) return false
  if (!employee.registration || employee.registration.length === 0) return false
  if (!employee.shortName || employee.shortName.length < 2) return false
  if (!employee.shiftId || !employee.areaId) return false
  if (employee.hasUser && !employee.user.email) return false
  if (
    employee.hasUser &&
    (employee.user.email.length === 0 || !emailRegex.test(employee.user.email))
  )
    return false
  return true
}

export const formatDate = (date) => {
  if (!date) return
  const splitDate = date.split('/')
  return new Date(`${splitDate[1]}-${splitDate[0]}-${splitDate[2]}`)
}

export const dateToString = (date) => {
  if (!date) return
  const parsed = new Date(date)
  return `
    ${('0' + parsed.getDate()).slice(-2)}/${('0' + (parsed.getMonth() + 1)).slice(
    -2
  )}/${parsed.getFullYear()}`
}
