import React, { useState, useEffect, useRef } from 'react'
import Modal from 'components/Modal'
import { FlexContainer, FlexColumn, Title, OutlineButton, Scrollable } from 'styles/components'
import { withNamespaces } from 'react-i18next'
import { inject } from 'mobx-react'
import EmployeeForm from './employeeForm'
import { employeeService } from 'services'
import { validateEmployee, formatDate, dateToString } from '../functions'
import { CircularLoader } from 'styles/components/page'
import EmployeeContext from './context'
import { userService, areaService, shiftService, roleService } from 'services'

const ModalImport = ({ open, closeFn, t, rootStore, editing, updateEmployee }) => {
  const formRef = useRef(null)

  const [hasErrors, setHasErrors] = useState(false)
  const [loading, setLoading] = useState(false)

  const [employees, setEmployees] = useState([])
  const [employeesViewModel, setEmployeesViewModel] = useState([])
  const workStations = rootStore.workStationStore.workStations
  const [roles, setRoles] = useState([])
  const [shifts, setShifts] = useState([])
  const [areas, setAreas] = useState([])

  const [users, setUsers] = useState([])

  const [loadingData, setLoadingData] = useState(true)
  const [editingEmployee, setEditingEmployee] = useState({})

  const { notify } = rootStore.toastMiddleware

  const loadPage = async () => {
    let employees = await employeeService.getAllWithDeactivated()

    let users = await userService.getAllWithDeactivated()
    users = users.filter((u) => u.email !== 'superuser@plantscanner.io')

    let areas = await areaService.getAll()
    let shifts = await shiftService.getAll()

    let roles = await roleService.getAll()
    roles = roles.filter((r) => r.name !== 'superuser')

    setUsers(users)
    setAreas(areas)
    setRoles(roles)

    setShifts(shifts)
    setEmployees(employees)
  }

  const handleCancel = () => closeFn()

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const submittedEmployee = {
      ...editingEmployee,
      born: editingEmployee.born ? null : formatDate(editingEmployee.born),
    }

    try {
      const updatedEmployee = await employeeService.update(submittedEmployee)
      updateEmployee(updatedEmployee)
      setLoading(false)
    } catch (error) {
      notify(error.message || error.toString(), {
        type: 'error',
        position: 'bottom-right',
      })
      setLoading(false)
    }
  }

  const updateModalEmployee = (emp) => {
    if (emp !== editingEmployee) setEditingEmployee(emp)
  }

  useEffect(() => {
    if (!validateEmployee(editingEmployee)) return setHasErrors(true)
    setHasErrors(false)
  }, [editingEmployee])

  useEffect(() => {
    if (open && employees.length === 0) loadPage()
  }, [open])

  const canSave = hasErrors

  useEffect(() => {
    buildEmployeesViewModel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees])

  const buildEmployeesViewModel = () => {
    if (!employees || !employees.length) return

    const employeesById = employees.reduce((acc, employee) => {
      acc[employee.id] = employee
      return acc
    }, {})
    const usersById = users.reduce((acc, user) => {
      acc[user.id] = user
      return acc
    }, {})
    const areasById = areas.reduce((acc, area) => {
      acc[area.id] = area
      return acc
    }, {})
    const shiftsById = shifts.reduce((acc, shift) => {
      acc[shift.id] = shift
      return acc
    }, {})

    const employeesViewModel = employees.map((employee) => {
      const employeeViewModel = { ...employee }
      employeeViewModel.supervisor = employeesById[employeeViewModel.supervisorId]
      if (employeeViewModel.userId) {
        employeeViewModel.user = usersById[employeeViewModel.userId]
      }
      employeeViewModel.area = areasById[employeeViewModel.areaId]
      employeeViewModel.shift = shiftsById[employeeViewModel.shiftId]
      return employeeViewModel
    })

    setEmployeesViewModel(employeesViewModel)

    const tempEmployee = employeesViewModel.find((e) => e.id === editing.id)
    const user = users.find((u) => u.id === tempEmployee.userId)

    setEditingEmployee({
      ...tempEmployee,
      born: editing.born && dateToString(editing.born),
      photo: { file: null, preview: null },
      cannotEditEmail: editing.user && editing.user.email.length > 0,
      hasUser: user && user.email.length > 0,
      user: { ...user },
    })

    setLoadingData(false)
  }

  return (
    <Modal
      isOpen={open}
      role={'dialog'}
      className="modal-90w"
      toggle={closeFn}
      scrollable
      centered
      backdrop={true}
      size="lg"
    >
      {loadingData && <CircularLoader />}
      {!loadingData && (
        <>
          <Modal.Header>
            <FlexContainer direction="row" justify="space-between" align="baseline">
              <FlexColumn align="center">
                <Title fontSize={1.4} fontSizeSm={1.2}>
                  {editingEmployee.shortName}
                </Title>
              </FlexColumn>
              <FlexColumn direction="row" align="center">
                <Title fontSize={1} fontSizeSm={0.8} onClick={closeFn} pointer>
                  {t('common.close')}
                </Title>
              </FlexColumn>
            </FlexContainer>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={onSubmit} ref={formRef}>
              <EmployeeContext.Provider
                value={{
                  employees,
                  shifts,
                  roles,
                  areas,
                  workStations,
                  users,
                  actions: {
                    validateRegistration: (registration, id) =>
                      employeesViewModel.find(
                        (e) => e.registration === registration && e.id !== id
                      ),
                    validateEmail: (email, id) => {
                      if (email === 'superuser@plantscanner.io') return { fullName: 'PlantScanner' }
                      const emp = employeesViewModel.find(
                        (e) => e.user && e.user.email === email && e.id !== id && !e.deactivated
                      )
                      if (emp) return emp
                      const user = users.find((user) => user.email === email && !user.deactivated)
                      if (user) {
                        const empUser = employeesViewModel.find(
                          (e) => e.user && e.user.id === user.id
                        )
                        if (!empUser || empUser.id !== id) return user
                      }
                      return undefined
                    },
                    activate: async (employee) => {
                      try {
                        await employeeService.activate(employee)

                        let employeesFilter = employees.map((e) => {
                          if (e.id === employee.id) e.deactivated = false
                          return e
                        })

                        setEmployees(employeesFilter)
                      } catch (error) {
                        notify(error.message || error.toString(), {
                          type: 'error',
                          position: 'bottom-right',
                        })
                      }
                    },
                    deactivate: async (employee) => {
                      try {
                        await employeeService.deactivate(employee)

                        let employeesFilter = employees.map((e) => {
                          if (e.id === employee.id) e.deactivated = true
                          return e
                        })

                        setEmployees(employeesFilter)
                      } catch (error) {
                        notify(error.message || error.toString(), {
                          type: 'error',
                          position: 'bottom-right',
                        })
                      }
                    },
                  },
                }}
              >
                <FlexContainer>
                  <Scrollable infinite loading={loading}>
                    {editingEmployee && editingEmployee.id && (
                      <EmployeeForm
                        waitId={!editingEmployee.id}
                        employee={editingEmployee}
                        cannotEditEmail={editingEmployee.cannotEditEmail}
                        updateEmployee={updateModalEmployee}
                      />
                    )}
                  </Scrollable>
                </FlexContainer>
                <FlexColumn direction={'row'} justify={'flex-end'}>
                  <OutlineButton danger onClick={handleCancel} pointer>
                    {t('common.cancel')}
                  </OutlineButton>
                  <OutlineButton
                    attention={!canSave}
                    pointer={!canSave}
                    primary
                    onClick={() => !canSave && formRef.current.dispatchEvent(new Event('submit'))}
                  >
                    {t('common.save')}
                  </OutlineButton>
                </FlexColumn>
              </EmployeeContext.Provider>
            </form>
          </Modal.Body>
        </>
      )}
    </Modal>
  )
}
export default withNamespaces()(inject('rootStore')(ModalImport))
