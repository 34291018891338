import React, { useState, useEffect, useCallback } from 'react'
import { Bar, Logo } from './styles'
import LogoSvg from 'images/logos/logo-sm.svg'
import { withNamespaces } from 'react-i18next'
import { inject } from 'mobx-react'
import { employeeService } from 'services'
import Profile from './components/profile'
import ButtonMenu from './components/buttonMenu'
import MobileButtonMenu from './components/mobileButtonMenu'
import { isMobile, isAndroid } from 'react-device-detect'
import { Link } from 'react-router-dom'

const Menu = ({ t, rootStore }) => {
  const { email } = rootStore.authStore
  const [employee, setEmployee] = useState()
  const isStaging =
    window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')

  const getEmployeeByEmail = useCallback(async () => {
    const responseEmployee = await employeeService.getByEmail(email)
    if (responseEmployee) {
      setEmployee(responseEmployee)
      rootStore.authStore.employee = responseEmployee
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  useEffect(() => {
    getEmployeeByEmail()
  }, [getEmployeeByEmail])

  const reloadEmployee = () => {
    getEmployeeByEmail()
  }

  return (
    <>
      <Bar id={'ps-menu'} isStaging={isStaging}>
        {isMobile || isAndroid ? <MobileButtonMenu /> : <ButtonMenu />}
        <Link to={'/'}>
          <Logo isMobile={isMobile || isAndroid}>
            <img src={LogoSvg} alt={t('menu.logo')} />
          </Logo>
        </Link>
        <Profile employee={employee} reloadEmployee={reloadEmployee} />
      </Bar>
    </>
  )
}

export default withNamespaces()(inject('rootStore')(Menu))
