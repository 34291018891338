import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Rotate } from '../keyframes/animations'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  padding: 10px 10px 0px 20px;
  display: inline-flex;
  flex-flow: wrap;

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2333;
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: 2% 5%;
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0px;
    `}
`

export const MaxSize = styled.div`
  max-width: ${({ maxWidth }) => maxWidth}px;
  max-height: ${({ height }) => height}px;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
  flex: ${({ flex }) => flex || ''};
  overflow: ${({ overflow }) => overflow || ''};
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  padding-left: ${({ paddingLeft }) => paddingLeft || 0}px;
  padding-right: ${({ paddingRight }) => paddingRight || 0}px;

  &:after {
    content: '';
    opacity: 0;
    display: none;
    transition: all 0.3s ease;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-delay: 0.3s;
    animation: ${Rotate} 1s cubic-bezier(0.54, 0.23, 0.57, 0.93) infinite;
  }

  ${({ loading, theme }) =>
    loading &&
    css`
      position: relative;
      &:after {
        content: '';
        opacity: 1;
        display: block;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        justify-self: flex-start;
        display: block;
        border: 2px solid #cecece;
        border-left: 2px solid ${theme.colors.principalColor};
        border-top: 2px solid ${theme.colors.principalColor};
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        margin: auto;
      }
    `}

  ${({ unique }) =>
    unique &&
    css`
      width: 100%;
    `}

  ${({ nonSelect }) =>
    nonSelect &&
    css`
      user-select: none;
    `}
  
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ relative }) =>
    relative &&
    css`
      position: relative;
    `}

  ${({ wrap }) =>
    wrap &&
    css`
      flex-wrap: wrap;
    `}

  ${media.lessThan('medium')`
    ${({ smFull }) =>
      smFull &&
      css`
        flex: 12 0 100%;
        margin-bottom: 10px;
      `}
  `}
  
  @media (max-width: 768px) {
    ${({ mdHidden }) =>
      mdHidden &&
      css`
        display: none;
      `}
  }

  ${media.lessThan('small')`
  ${({ smMarginTop }) =>
    smMarginTop &&
    css`
      margin-top: ${({ smMarginTop }) => smMarginTop}px;
    `}

    ${({ smHidden }) =>
      smHidden &&
      css`
        display: none;
      `}
  `}
`

export const FlexContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ direction }) => direction || 'column'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  align-items: ${({ align }) => align || 'inherit'};
  justify-content: ${({ justify }) => justify || 'start'};
  width: 100%;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;

  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}

  ${({ loading, theme }) =>
    loading &&
    css`
      overflow: hidden;

      &:after {
        content: '';
        opacity: 1;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        justify-self: flex-start;
        animation: ${Rotate} 1s cubic-bezier(0.54, 0.23, 0.57, 0.93) infinite;
        display: block;
        border: 2px solid ${theme.colors.gray};
        border-left: 2px solid ${theme.colors.principalColor};
        border-top: 2px solid ${theme.colors.principalColor};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 222;
      }

      &:before {
        content: '';
        transform: scale(1);
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        opacity: 1;
      }
    `}
`

export const ShadowContainer = styled.div`
  width: 100%;
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 20px;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.backgroundContainer};
`

export const Scrollable = styled.div`
  max-height: ${({ max }) => max ?? 250}${({ percentage, vh }) => (percentage ? '%' : vh ? 'vh' : 'px')};
  min-height: ${({ min }) => min ?? 0}${({ percentage, vh }) => (percentage ? '%' : vh ? 'vh' : 'px')};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  transition: max-height 1s, min-height 1s;

  &::-webkit-scrollbar {
    width: 4px;
  }

  ˆ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.baseInputBorderColor};
  }

  &:before {
    content: '';
    opacity: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: all 0.3s ease;
    transition-property: transform, opacity;
    transition-duration: 0.1s, 0.5s;
    transition-delay: 0s, 0.2s;
  }

  &:after {
    content: '';
    opacity: 0;
    transition: all 0.3s ease;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-delay: 0.3s;
    animation: ${Rotate} 1s cubic-bezier(0.54, 0.23, 0.57, 0.93) infinite;
  }

  ${({ show }) =>
    !show &&
    css`
      max-height: 0px;
      min-height: 0px;
    `}

  ${({ loading, theme }) =>
    loading &&
    css`
      overflow: hidden;

      &:after {
        opacity: 1;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        justify-self: flex-start;
        display: block;
        border-left: 2px solid ${theme.colors.primary};
        border-top: 2px solid ${theme.colors.primary};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      &:before {
        content: '';
        transform: scale(1);
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        opacity: 1;
      }
    `}

  ${({ infinite }) =>
    infinite &&
    css`
      max-height: fit-content;
      min-height: fit-content;
      overflow: initial;
    `}
`

export const FloattingContainer = styled.div`
  position: fixed;
  bottom: 2%;
  right: 2%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
`

export const Form = styled.form``

export const List = styled(FlexContainer)`
  flex-direction: row;
  flex-wrap: wrap;
`

export const ListTile = styled(FlexColumn)`
  flex: 2 0 25%;
  min-height: 108px;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.backgroundContainer};
  margin: 5px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.01);
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  max-width: 33%;
  border-radius: 4px;
  overflow: hidden;

  ${({ loading, theme }) =>
    loading &&
    css`
      &:before {
        content: '';
        position: absolute;
        animation: ${Rotate} 1s cubic-bezier(0.54, 0.23, 0.57, 0.93) infinite;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        justify-self: flex-start;
        display: block;
        border-left: 1px solid ${theme.colors.principalColor};
        border-top: 1px solid ${theme.colors.principalColor};
        position: absolute;
        right: 5px;
        bottom: 5px;
        margin: auto;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.backgroundContainerHover};
    `}
    
  @media (max-width: 768px) {
    flex: 2 0 40%;
    max-width: 48%;
    overflow: hidden;
  }

  @media (max-width: 425px) {
    flex: 2 0 100%;
    max-width: 100%;
  }

  &:hover {
    transform: scale(0.98);
    background: ${({ theme }) => theme.colors.backgroundContainerHover};
  }

  &:active {
    transform: scale(0.98);
    background: ${({ theme }) => theme.colors.backgroundContainerHover};
  }
`
