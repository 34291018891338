import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const create = async (type, assignedWorkStationId, note) => {
  try {
    const data = [{ type, assignedWorkStationId, note }]
    const { data: programmedStoppage } = await axios.post('programmedStoppage', data)
    return programmedStoppage
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
