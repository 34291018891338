import React, { useRef } from 'react'
import { Wrapper, Label, Input, ErrorMessage, Subtitle } from './styles'
import InputMask from 'react-input-mask'

const PrimaryInputWrapper = (props) => {
  const {
    placeholder,
    mask,
    value,
    label,
    onChange,
    error,
    errorMessage,
    vertical,
    subTitle,
    stretch,
    ...rest
  } = props
  const inputRef = useRef(null)

  return (
    <Wrapper
      vertical={vertical}
      name={label}
      hasValue={value && value.length > 0}
      stretch={stretch}
    >
      {label && <Label>{label}</Label>}
      {!mask && (
        <Input
          {...rest}
          ref={inputRef}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          label={label}
          error={error}
        />
      )}
      {mask && (
        <InputMask
          mask={mask}
          maskChar={null}
          onChange={onChange}
          {...rest}
          ref={inputRef}
          placeholder={placeholder}
          value={value}
        >
          {(inputProps) => (
            <Input
              {...inputProps}
              ref={inputRef}
              placeholder={placeholder}
              value={value}
              label={label}
              error={error}
            />
          )}
        </InputMask>
      )}
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {!error && subTitle && <Subtitle>{subTitle}</Subtitle>}
    </Wrapper>
  )
}

export default PrimaryInputWrapper
