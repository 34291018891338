import styled, { css } from 'styled-components'
import Select from 'react-select'
import CreatableSelect from 'react-select/lib/Creatable'
import CreatableAsyncSelect from 'react-select/lib/AsyncCreatable'

const BaseInput = styled.input`
  width: 100%;
  height: ${({ height }) => height ?? 48}px;
  border: none;
  border-radius: 4px;
  padding-left: 20px;
  font-size: 12px;
  background: ${(props) => props.theme.colors.baseInputColor};
  border: 2px solid ${(props) => props.theme.colors.baseInputColor};
  border-bottom: 2px solid ${(props) => props.theme.colors.baseInputBorderColor};
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: ${(props) => props.theme.colors.textColor};

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.inputFocusColor} !important;
    caret-color: ${(props) => props.theme.colors.inputFocusColor} !important;
  }

  ${({ errorBorder }) =>
    errorBorder &&
    css`
      border: 2px solid ${(props) => props.theme.colors.danger} !important;
      border-bottom: 2px solid ${(props) => props.theme.colors.danger} !important;
    `}
`

const checkedStyles = css`
  &:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f00c';
    font-size: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const BaseCheckBox = styled.div`
  display: flex;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  transition: all 150ms;
  justify-content: center;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 10}px;
  transition: all 0.3s ease;
  ${({ checked }) => checked && checkedStyles};
`

export const InputStyle = styled.div`
  width: 100%;
  height: ${({ height }) => height ?? 48}px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  background: ${(props) => props.theme.colors.baseInputColor};
  border: 2px solid ${(props) => props.theme.colors.baseInputColor};
  border-bottom: 2px solid ${(props) => props.theme.colors.baseInputBorderColor};
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: ${(props) => props.theme.colors.textColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;

  input {
    width: 80%;
    height: 100%;
    font-size: 12px;
    border: none;
    background: transparent;
    transition: all 0.3s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: ${(props) => props.theme.colors.textColor};
  }

  &:focus-within {
    border: 2px solid ${(props) => props.theme.colors.inputFocusColor} !important;
    caret-color: ${(props) => props.theme.colors.inputFocusColor} !important;
  }

  ${({ errorBorder }) =>
    errorBorder &&
    css`
      border: 2px solid ${(props) => props.theme.colors.danger} !important;
      border-bottom: 2px solid ${(props) => props.theme.colors.danger} !important;
    `}
`

export const PrimaryInput = styled(BaseInput)``

export const HiddenInput = styled.input`
  display: none;
`

export const PrimarySelect = styled(Select)`
  overflow: visible;
  position: relative;
  width: 100%;
`

export const CreatableSelectWrapper = styled(CreatableSelect)`
  overflow: visible;
  position: relative;
  width: 100%;
`

export const CreatableAsyncSelectWrapper = styled(CreatableAsyncSelect)`
  overflow: visible;
  position: relative;
  width: 100%;
`
