import React from 'react'
import { useNotification } from 'hooks'
import { useEffect } from 'react'

export const withNotification = (ComponentWrapped) => {
  return (props) => {
    const [defaultNotification, successNotification, errorNotification, warningNotification] =
      useNotification()

    useEffect(() => {
      window.defaultNotification = defaultNotification
      window.successNotification = successNotification
      window.errorNotification = errorNotification
      window.warningNotification = warningNotification
    }, [])

    return (
      <ComponentWrapped
        defaultNotification={defaultNotification}
        successNotification={successNotification}
        errorNotification={errorNotification}
        warningNotification={warningNotification}
        {...props}
      />
    )
  }
}
