import React, { useState, useEffect, useRef } from 'react'
import { CreatableAsyncSelectWrapper, PrimarySelect, CircularLoaderSm } from 'styles/components'
import { Wrapper, FloatingContainer, Label, Input } from './styles'
import { withTheme } from 'styled-components'

const PrimaryInputWrapper = (props) => {
  const {
    placeholder,
    height,
    width,
    onChange,
    ref,
    isMulti,
    label,
    required,
    theme,
    value,
    loading,
    creatable,
    onCreate,
    loadOptions,
    defaultOptions,
    onBlur,
    ...rest
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(value)
  const selectRef = useRef(useRef)

  useEffect(() => {
    if (selectedOption) {
      onChange(selectedOption)
      setIsOpen(false)

      if (onBlur) onBlur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({
      boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: theme.colors.principalWhite,
    }),
  }

  useEffect(() => {
    function clickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
        if (onBlur) onBlur()
      }
    }
    document.addEventListener('mousedown', clickOutside)
    return () => {
      document.removeEventListener('mousedown', clickOutside)
    }
  }, [selectRef])

  return (
    <Wrapper
      name={label}
      ref={selectRef}
      isOpen={isOpen}
      height={height}
      width={width}
      selectedOption={selectedOption}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      isLoading={loading}
      onClick={loading ? () => {} : () => setIsOpen(!isOpen)}
    >
      {loading && (
        <div style={{ position: 'absolute', right: 5, bottom: 7 }}>
          <CircularLoaderSm></CircularLoaderSm>
        </div>
      )}
      {label && <Label>{label}</Label>}
      <Input
        required={required}
        placeholder={placeholder}
        value={value ? value.label : ''}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <FloatingContainer>
          {!creatable && (
            <PrimarySelect
              autoFocus
              backspaceRemovesValue={false}
              menuIsOpen
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              {...rest}
              isMulti={isMulti}
              ref={ref}
              value={value}
              label={label}
              styles={selectStyles}
              placeholder={placeholder}
              tabSelectsValue={false}
              onChange={(option) => setSelectedOption(option)}
            />
          )}
          {creatable && (
            <CreatableAsyncSelectWrapper
              autoFocus
              backspaceRemovesValue={false}
              menuIsOpen
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              // {...rest}
              isMulti={isMulti}
              ref={ref}
              value={value}
              label={label}
              isLoading={loading}
              formatCreateLabel={(option) => `Criar ${option}`}
              styles={selectStyles}
              placeholder={placeholder}
              onCreateOption={onCreate}
              tabSelectsValue={false}
              loadOptions={loadOptions}
              defaultOptions={defaultOptions}
              onChange={(option) => setSelectedOption(option)}
            />
          )}
        </FloatingContainer>
      )}
    </Wrapper>
  )
}

export default withTheme(PrimaryInputWrapper)
