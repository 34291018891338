import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const login = async (email, password) => {
  try {
    const { data: authResult } = await axios.post('login', { email, password })
    return authResult
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const renewLogin = async (email, refreshToken) => {
  const { data: authResult } = await axios.post('login', {
    email,
    refreshToken,
  })
  return authResult
}
