import React from 'react'
import { Wrapper, Label, Chip, ChipsContainer } from './styles'

const PrimaryChips = ({ selectedValues, onChange, availableOptions, label }) => {
  return (
    <Wrapper name={label}>
      <Label>{label}</Label>
      <ChipsContainer>
        {availableOptions.map((av) => (
          <Chip
            key={av.value}
            onClick={() => onChange(av)}
            selected={selectedValues && selectedValues.some((ev) => ev.value === av.value)}
          >
            {av.label}
          </Chip>
        ))}
      </ChipsContainer>
    </Wrapper>
  )
}

export default PrimaryChips
