//FIXME alterar as cores no modo dark, copiar resultado das cores do DarkReader
export default {
  name: 'dark',
  colors: {
    principalWhite: '#fff',
    principalDark: '#000',
    principalColor: 'rgb(98, 132, 38)',
    principalColorHover: 'rgb(98, 132, 38)',
    inputFocusColor: 'rgb(0, 65, 169)',
    inputColorError: '#ef4b4b',
    textColor: 'rgb(214, 211, 205)',
    black: '#333333',
    backgroundDefault: 'rgb(29, 30, 32)',
    backgroundDefaultTile: 'rgb(29, 30, 32)',
    backgroundContainer: 'rgb(24, 25, 27)',
    backgroundContainerHover: '#FAFAFA',
    baseInputColor: 'rgb(31, 33, 34)',
    baseInputBorderColor: 'rgb(54, 54, 59)',
    primary: 'rgb(0, 65, 169)',
    success: '#81AD32',
    warning: '#ffae42',
    danger: '#ef4b4b',
    userTileColors: ['#2671FF', '#FFBF66', '#615499', '#2E0ACC', '#CC5F0A'],
  },
}
