import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAll = async () => {
  try {
    const { data: materials } = await axios.get('material')
    return materials
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getById = async (id) => {
  try {
    const { data: material } = await axios.get(`material/${id}`)
    return material
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const search = async (search) => {
  try {
    const { data: materials } = await axios.get(`material/search/${search}`)
    return materials
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAvailableUoMs = async () => {
  try {
    const { data: material } = await axios.get('material/availableUoMs')
    return material
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
