import { useEffect, useState, useRef } from 'react'

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()
  const [timer] = useState(delay)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (timer !== null) {
      let id = setInterval(tick, timer)
      return () => clearInterval(id)
    }
  }, [timer])
}
