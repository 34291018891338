export const success = {
  style: {
    backgroundColor: '#81AD32',
    color: '#fff',
  },
}

export const error = {
  style: {
    backgroundColor: '#db2828',
    color: '#fff',
  },
}


export const warning = {
  style: {
    backgroundColor: '#ffae42',
    color: '#fff',
  },
}