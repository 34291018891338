/* eslint-disable no-console */
import { observable, computed, action, reaction } from 'mobx'
import * as Oidc from 'oidc-client'
import { userService } from 'services'
// import { appInsights } from 'AppInsights'
import ReactGA from 'react-ga'
import { trackingId } from 'config/constants'

const config = {
  authority: `${process.env.REACT_APP_OAUTH2_AUTHORITY}`,
  client_id: `${process.env.REACT_APP_OAUTH2_CLIENT_ID}`,
  redirect_uri: `${process.env.REACT_APP_APP_URL}/login-callback`,
  silent_redirect_uri: `${process.env.REACT_APP_APP_URL}/login-silent-callback`,
  response_type: 'code',
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  scope: 'offline_access openid profile email phone plantscanner-portal-api plantscanner-api',
  post_logout_redirect_uri: `${process.env.REACT_APP_APP_URL}/logout-callback`,
}

export default class AuthStore {
  @observable initialized = false
  @observable retry = false
  @observable inProgress = false
  @observable errors = undefined
  @observable userManager = undefined
  @observable user = undefined
  @observable email = undefined
  @observable roles = []
  @observable accessToken = undefined
  @observable selectedTenant = undefined
  @observable availableTenants = undefined
  @observable getUserRolesInProcess = false
  @observable tenantFeatures = undefined

  constructor(rootStore) {
    this.rootStore = rootStore

    Oidc.Log.logger = console
    Oidc.Log.level = Oidc.Log.WARN

    console.debug('OIDC - authStore was initialized')

    this.userManager = new Oidc.UserManager(config)
    this.userManager.clearStaleState()
    reaction(
      () => this.getUserRolesInProcess,
      (value) => {
        if (value) this.setUserRoles()
      }
    )
  }

  @computed
  get isLoggedIn() {
    return !!this.accessToken
  }

  get accessTokenExpired() {
    return this.user?.expired ?? true
  }

  @action setGetUserRolesInProcess() {
    this.getUserRolesInProcess = true
  }

  @action async initialize() {
    console.debug('OIDC - initialize was called')
    const user = await this.userManager.getUser()
    console.debug('OIDC - initialize has user in userManager', user)
    if (user) await this.updateUser(user)
    else if (this.retry <= 3)
      return setTimeout(() => {
        this.retry++
        this.initialize()
      }, 1000)

    this.retry = 0
    this.initialized = true
    this.setGetUserRolesInProcess()
    console.debug('OIDC - initialize was finished')
  }

  @action async setUserRoles() {
    console.debug('OIDC - setUserRoles was called')
    if (this.isLoggedIn) {
      this.roles = (await userService.getUserRolesByEmail(this.email)).map((ro) => ro.name)
      console.debug(`OIDC - setUserRoles was called - isLoggedIn ${this.isLoggedIn}`)
      console.debug('OIDC - setUserRoles was called - roles', this.roles)
    }
    this.getUserRolesInProcess = false
  }

  @action async login(tenant) {
    this.inProgress = true
    this.errors = undefined
    console.debug(`OIDC - Login was walled with ${tenant}`)
    if (tenant && tenant !== this.selectedTenant) {
      const newConfig = {
        ...config,
        acr_values: `tenant:${tenant}`,
      }

      this.userManager = new Oidc.UserManager(newConfig)
    }
    return await this.userManager.signinRedirect()
  }

  @action async completeLogin() {
    this.inProgress = true
    this.errors = undefined

    const user = await this.userManager.signinRedirectCallback()

    console.debug('OIDC - completeLogin was walled with', user)
    if (user) {
      await this.updateUser(user)
      this.inProgress = false
    } else await this.userManager.signinRedirect()
  }

  @action async loginSilent(tenant) {
    this.inProgress = true
    this.errors = undefined

    if (tenant && tenant !== this.selectedTenant) {
      const newConfig = {
        ...config,
        acr_values: `tenant:${tenant}`,
      }

      this.userManager = new Oidc.UserManager(newConfig)
    }
    await this.userManager.signinSilent()
  }

  @action async completeLoginSilent() {
    this.inProgress = true
    this.errors = undefined

    const user = await this.userManager.signinSilentCallback()
    if (user) {
      this.inProgress = false
      await this.updateUser(user)
    }
  }

  refreshAccessTokenPromise = undefined
  @action refreshAccessToken() {
    console.debug('OIDC - refreshAccessToken was walled')

    if (this.refreshAccessTokenPromise) {
      console.debug('OIDC - refreshAccessTokenPromise in progress')
      return this.refreshAccessTokenPromise
    } else {
      this.refreshAccessTokenPromise = (async () => {
        try {
          const user = await this.userManager.signinSilent()
          await this.updateUser(user)
        } catch (err) {
          if (err.message === 'invalid_grant') await this.clearUser()
        } finally {
          this.refreshAccessTokenPromise = undefined
        }
      })()

      return this.refreshAccessTokenPromise
    }
  }

  async updateUser(user) {
    console.debug('OIDC - updateUser was called with', user)
    if (!user) return
    await this.userManager.storeUser(user)
    // appInsights.setAuthenticatedUserContext(
    //   `${user.profile['sub']}-${user.profile['selected-tenant']}`,
    //   user.profile['email'],
    //   true
    // )
    this.user = user
    this.email = user.profile['email']
    this.selectedTenant = user.profile['selected-tenant']
    this.availableTenants = user.profile['available-tenants']
    this.accessToken = user.access_token
    this.tenantFeatures = user.profile['selected-tenant-features']
      ? JSON.parse(user.profile['selected-tenant-features'])
      : undefined

      localStorage.setItem('user-email', user.profile['email'])
    localStorage.setItem('user-tenant', user.profile['selected-tenant'])

    ReactGA.initialize(trackingId, { debug: true })
    ReactGA.set({ userId: user.profile['email'] })
  }

  async clearUser() {
    console.debug('OIDC - clearUser was called with in store', this.user)
    // appInsights.clearAuthenticatedUserContext()
    await this.userManager.removeUser()
    this.user = undefined
    this.accessToken = undefined
    this.email = undefined
    this.roles = []
    this.selectedTenant = undefined
    this.availableTenants = undefined
  }

  @action
  async selectTenant(tenant) {
    await this.refreshAccessToken()
    this.login(tenant).then(() => this.clearUser())
  }

  @action
  logout() {
    this.inProgress = true
    this.userManager.signoutRedirect().catch((error) => {
      console.error(error)
    })
  }

  @action
  completeLogout() {
    console.debug('OIDC - completeLogout was called')
    this.inProgress = true
    this.userManager
      .signoutRedirectCallback()
      .then(() => {
        this.userManager.removeUser()
      })
      .then(() => {
        this.user = null
        this.selectedTenant = undefined
        this.availableTenants = undefined
        this.inProgress = false
      })
      .catch((error) => console.error(error))
  }
}
