import './Vendor'
import './styles/bootstrap.scss'
import './styles/app.scss'
import './styles/themes/theme-0.scss'

import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { inject } from 'mobx-react'
import GlobalStyle from './styles/global'
import { ThemeProvider } from 'styled-components'
import { light } from './styles/patterns'
import { ToastContainer } from 'react-toastify'
import SnackbarProvider from 'react-simple-snackbar'
import 'react-toastify/dist/ReactToastify.min.css'
import 'antd/dist/antd.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider, ErrorBoundary } from '@rollbar/react'
const queryClient = new QueryClient()

function App(props) {
  useEffect(
    () => {
      if (!props.rootStore.authStore.initialized) props.rootStore.authStore.initialize()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const rollbarConfig = {
    accessToken: 'f4ae5899c54e45c1b52824585a825366',
    environment: 'testenv',
  }

  return (
    <>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <ThemeProvider theme={light}>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient} contextSharing={true}>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </QueryClientProvider>
            </SnackbarProvider>
            <GlobalStyle />
            <ToastContainer />
          </ThemeProvider>
        </ErrorBoundary>
      </Provider>
    </>
  )
}
export default inject('rootStore')(App)
