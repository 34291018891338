export function extractError(content) {
  if (!content) return { message: 'Erro na requisição com o servidor' }

  const { response } = content
  if (!response) return { message: 'Erro na requisição com o servidor' }

  const { status, data: error } = response
  if (status === 401) return { message: 'Usuário ou senha inválido' }
  if (status === 404) return { message: error.message ?? 'Não localizado' }
  if (status !== 500) return { message: error.message ?? 'Erro na requisição com o servidor' }
  if (!error) return { message: 'Erro de servidor' }

  return error
}
