import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject } from 'mobx-react'

@inject('rootStore')
class ProtectedRoute extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.location !== nextProps.location
  }

  render() {
    const { component: Component, allowedRoles, ...rest } = this.props
    const { isLoggedIn, roles } = this.props.rootStore.authStore
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isLoggedIn) return <Redirect to="/login" />
          if (!allowedRoles || roles.some((userRole) => allowedRoles.includes(userRole)))
            return <Component {...props} />
          return <Redirect to="/" />
        }}
      />
    )
  }
}

export default ProtectedRoute
