import { observable, action, reaction, computed } from 'mobx'
import { applicationConfigurationService } from 'services'

export default class ApplicationStore {
  @observable applicationConfiguration = {
    features: {
      onScreenKeyboard: true,
      overviewPlanning: false,
      isWebcamCapable: false,
      qrCodeEmployeeLogin: false,
    },
  }

  @computed get qrCodeEmployeeLoginEnabled() {
    return (
      this.applicationConfiguration.features.isWebcamCapable &&
      this.applicationConfiguration.features.qrCodeEmployeeLogin
    )
  }

  constructor(rootStore) {
    this.rootStore = rootStore

    reaction(
      () => this.rootStore.authStore.accessToken,
      () => {
        if (!this.rootStore.authStore.accessTokenExpired) this.getConfigurations()
      }
    )

    navigator.mediaDevices.ondevicechange = () => {
      this.detectWebcam().then((isWebcamCapable) => this.updateWebCamCapable({ isWebcamCapable }))
    }

    this.detectWebcam().then((isWebcamCapable) => this.updateWebCamCapable({ isWebcamCapable }))
  }

  @action getConfigurations() {
    applicationConfigurationService
      .get()
      .then((configurations) => {
        this.applicationConfiguration = {
          ...this.applicationConfiguration,
          ...configurations,
        }
      })
      .catch((err) => {})
  }

  @action updateWebCamCapable({ isWebcamCapable }) {
    const isSamsungTablet = !!navigator.userAgent.match(/SM-T51[05]/i)
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0
    const onScreenKeyboard = isTouchDevice && !isSamsungTablet
    this.applicationConfiguration.features = {
      ...this.applicationConfiguration.features,
      isWebcamCapable,
      onScreenKeyboard,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async detectWebcam() {
    const md = navigator.mediaDevices
    if (!md || !md.enumerateDevices) return false
    const devices = await md.enumerateDevices()
    return devices.some((device) => 'videoinput' === device.kind)
  }
}
