import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAllOperatorsPauses = async () => {
  try {
    const { data: pauses } = await axios.get('employeeLoggedHoursStatus')
    return pauses
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllMachinePauses = async () => {
  try {
    const { data: pauses } = await axios.get('workOrderRuntimeStatus')
    return pauses
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (formData, pause) => {
  try {
    if (pause === 'machine') {
      await axios.patch('workOrderRuntimeStatus', formData)
    }
    if (pause === 'operator') {
      await axios.patch('employeeLoggedHoursStatus', formData)
    }
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const toggle = async (formData, pause) => {
  try {
    if (pause === 'machine') {
      return await axios.patch(`workOrderRuntimeStatus/toggle/${formData}`)
    }
    if (pause === 'operator') {
      return await axios.patch(`employeeLoggedHoursStatus/toggle/${formData}`)
    }
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (formData, pause) => {
  try {
    if (pause === 'machine') {
      const response = await axios.post('workOrderRuntimeStatus', formData)
      const { data } = response
      return data
    }
    if (pause === 'operator') {
      const response = await axios.post('employeeLoggedHoursStatus', formData)
      const { data } = response
      return data
    }
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
