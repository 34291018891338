import axios from 'axios'
import { extractError } from '../utils/errorHandler'
import { API_SERVER_RETENTION } from '../../config/app.config'

export const getAll = async () => {
  try {
    const { data: process } = await axios.get('process', {
      baseURL: API_SERVER_RETENTION,
    })
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllWithMaterials = async () => {
  try {
    const { data: process } = await axios.get('process?include=material', {
      baseURL: API_SERVER_RETENTION,
    })
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const toggleStatus = async (updated) => {
  try {
    const { data: process } = await axios.patch('process/toggleStatus', updated, {
      baseURL: API_SERVER_RETENTION,
    })
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllActivesWithMaterials = async () => {
  try {
    const { data: process } = await axios.get('process/actives', {
      baseURL: API_SERVER_RETENTION,
    })
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getById = async (id) => {
  try {
    const { data: process } = await axios.get(`process/${id}`)
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (processDb) => {
  try {
    const { data: process } = await axios.post('process', [processDb])
    return process
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (process) => {
  try {
    const { data: updatedProcess } = await axios.put(`process/${process.id}`, process)
    return updatedProcess
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const linkToAreaOrWorkStation = async ({ processId, workStationId, areaId }) => {
  try {
    const { data: updatedProcess } = await axios.patch('process/linkToAreaOrWorkStation', {
      processId,
      workStationId,
      areaId,
    })
    return updatedProcess
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
