import axios from 'axios'

const headers = {
  Authorization:
    'IST.eyJraWQiOiJQb3pIX2FDMiIsImFsZyI6IlJTMjU2In0.eyJkYXRhIjoie1wiaWRcIjpcIjM3OTZhNDVlLTljM2ItNGI1My05YzFmLTNkOTIwNmY5MWFhYVwiLFwiaWRlbnRpdHlcIjp7XCJ0eXBlXCI6XCJhcHBsaWNhdGlvblwiLFwiaWRcIjpcImM4ZGJmMDYyLTU3ZTEtNDRhNS1iZjljLWE1NzU2MmY1NWUzY1wifSxcInRlbmFudFwiOntcInR5cGVcIjpcImFjY291bnRcIixcImlkXCI6XCI5ODM5YWM1ZC1iZTcyLTRhOWQtOGEyZi1iMjQ0ZjMzZWMyM2JcIn19IiwiaWF0IjoxNjgxNDc4OTAyfQ.nJKkl5_1CFyD61lQTOUSz5yQfDi_eaYIUKA9k9lB7UE23sMKUIQ0CJyNAhDoXHsq5AEWSQ6dtchtWuh1gwisr41rjpOp4o-qPBsgq7ngN58aRGYco3bBHFzpCNNE59cvNWphwMgLHQggaZ75Kzej-t82LE6CEpURLIx1LbGyyXB0up2xxHTeqUQuFPEcrkTxgNMt45FD0DZ9tJXxXBjzy-Pm8dPVSGlvF8g4lh8TAMkoS0l3w9l7QM3u_RlFshPGEiGiH41f7hgs-MXmbntzYw8O689wMlvy7bX8HHQ-_kVR8p5TTXkNe2yEtMiOf1dcxQaYhUmH7dzq8MdTVhdGmQ',
  'wix-account-id': '9839ac5d-be72-4a9d-8a2f-b244f33ec23b',
  'wix-site-id': 'e0f82b6d-cf57-4e6d-b7db-b292e2e3477a',
}

export const getPosts = async () => {
  const response = await axios.get('https://www.wixapis.com/blog/v3/posts', {
    headers,
    withCredentials: false,
  })

  return response.data?.posts
}
