import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAll = async () => {
  try {
    const { data: users } = await axios.get('user')
    return users
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getUserRolesByEmail = async (email) => {
  try {
    const { data: roles } = await axios.get(`role/userRolesByEmail?email=${email}`)
    return roles
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllWithDeactivated = async () => {
  try {
    const { data: users } = await axios.get('user/getAllWithDeactivated')
    return users
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getById = async (id) => {
  try {
    const { data: user } = await axios.get(`user/${id}`)
    return user
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getByIdWithDeactivated = async (id) => {
  try {
    const { data: user } = await axios.get(`user/${id}/getByIdWithDeactivated`)
    return user
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const remove = async (id) => {
  try {
    await axios.delete(`user/${id}`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (user) => {
  try {
    const { data: updatedUser } = await axios.put(`user/${user.id}`, user)
    return updatedUser
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (user) => {
  try {
    const { data: newUser } = await axios.post('user', user)
    return newUser
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const activate = async (user) => {
  try {
    await axios.post(`user/${user.id}/activateUser`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const deactivate = async (user) => {
  try {
    await axios.post(`user/${user.id}/deactivateuser`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
