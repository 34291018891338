import { Transfer, Tree } from 'antd'
import React from 'react'

const generateTree = (treeNodes = [], checkedKeys = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    children: generateTree(children, checkedKeys),
  }))

export const TreeTransfer = ({ dataSource, targetKeys, ...restProps }) => {
  const transferDataSource = []
  function flatten(list = []) {
    list.forEach((item) => {
      transferDataSource.push(item)
      flatten(item.children)
    })
  }

  flatten(dataSource)
  const checkedKeys = [...targetKeys]

  const onSelect = (keys, info) => {
    restProps.onChange(keys)
  }

  const onCheck = (keys, info) => {
    restProps.onChange(keys)
  }

  return (
    <Tree
      key={`tree-transfer-${checkedKeys.length}`}
      checkable
      defaultExpandAll
      checkedKeys={checkedKeys}
      treeData={generateTree(dataSource, targetKeys)}
      onCheck={onCheck}
      onSelect={onSelect}
    />
  )
}
