import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getById = async (id, includeDeactivated = false) => {
  try {
    const query = {}
    if (includeDeactivated === true) {
      query.include = [query.include, 'deactivated'].filter(Boolean).join(',')
    }

    const { data: tools } = await axios.get(`tool/${id}`, { params: query })
    return tools
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAll = async (includeDeactivated = false) => {
  try {
    const query = {}
    if (includeDeactivated === true) {
      query.include = [query.include, 'deactivated'].filter(Boolean).join(',')
    }

    const { data: tools } = await axios.get('tool', { params: query })
    return tools
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const remove = async (id) => {
  try {
    await axios.delete(`tool/${id}`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (tool) => {
  try {
    const { data: updatedTool } = await axios.put(`tool/${tool.id}`, tool)
    return updatedTool
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (tool) => {
  try {
    const { data: newTool } = await axios.post('tool', tool)
    return newTool
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const activate = async (tool) => {
  try {
    await axios.post(`tool/${tool.id}/activate`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const deactivate = async (tool) => {
  try {
    await axios.post(`tool/${tool.id}/deactivate`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
