import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const TextBase = styled.p`
  margin: 0px;
  padding: 0px;
  margin-right: 5px;
  text-decoration: ${({ decoration }) => decoration ?? 'none'};
  -webkit-font-smoothing: antialiased;
  margin-left: ${(props) => props.marginLeft || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;

  ${({ inline }) =>
    inline &&
    css`
      white-space: pre;
    `}

  ${({ light }) =>
    light &&
    css`
      color: ${({ theme }) => theme.colors.principalWhite} !important;
    `}
    
  ${({ warning }) =>
    warning &&
    css`
      color: ${({ theme }) => theme.colors.warning} !important;
    `}

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.colors.danger} !important;
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${({ theme }) => theme.colors.success} !important;
    `}

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary} !important;
    `}

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${media.lessThan('small')`
  ${({ fontSizeSm }) =>
    fontSizeSm &&
    css`
      font-size: ${({ fontSizeSm }) => fontSizeSm}em !important;
    `}
  `}
`

export const Title = styled.h1`
  font-size: ${(props) => props.fontSize || 2}em;
  font-family: 'Montserrat', sans-serif;
  font-weight: ${(props) => props.fontWeight || 'bold'};
  color: ${(props) => props.color || props.theme.colors.textColor};
  margin: ${(props) => props.margin ?? '10px 10px'};

  ${media.lessThan('small')`
  ${({ fontSizeSm }) =>
    fontSizeSm &&
    css`
      font-size: ${({ fontSizeSm }) => fontSizeSm}em !important;
    `}
  `}

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ upper }) =>
    upper &&
    css`
      text-transform: uppercase;
    `}
  
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0px !important;
    `}

  ${({ inline }) =>
    inline &&
    css`
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ breakLine }) =>
    breakLine &&
    css`
      white-space: break-spaces !important;
    `}

    ${({ warning }) =>
    warning &&
    css`
      color: ${({ theme }) => theme.colors.warning} !important;
    `}

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.colors.danger} !important;
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${({ theme }) => theme.colors.success} !important;
    `}

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary} !important;
    `}
`

export const PageTitle = styled(Title)`
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
`

export const Text = styled(TextBase)`
  font-size: ${(props) => props.fontSize || 1}em;
  color: ${(props) => props.color || props.theme.colors.textColor};
  font-weight: ${(props) => props.fontWeight || 400};

  ${({ maxLength, textOverflow, whiteSpace }) =>
    maxLength &&
    css`
      max-width: ${maxLength}px;
      overflow: hidden;
      display: block;
      white-space: ${whiteSpace || 'nowrap'};
      text-overflow: ${textOverflow || 'ellipsis'};
    `}

  ${({ isAndroid }) =>
    isAndroid &&
    css`
      padding: 10px;
      background-color: #e2e2e2;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border-bottom: 3px solid rgba(0, 0, 0, 0.3);
      font-weight: bold;
    `}
`

export const DarkText = styled(Text)`
  color: ${(props) => props.theme.colors.textColor};

  ${({ vertical }) =>
    vertical &&
    css`
      white-space: normal !important;
    `}
`

export const Subtitle = styled(Text)`
  font-family: 'Source Sans Pro', sans-serif;

  ${({ vertical }) =>
    vertical &&
    css`
      margin: 0px !important;
    `}
`

export const Label = styled(PageTitle)`
  line-height: 20px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 'bold'};
  font-size: ${({ fontSize }) => fontSize ?? '0.9em'};
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0px 0px 5px 0px;
  margin-right: ${({ marginRight }) => marginRight ?? 0}px;
  margin-left: ${({ marginLeft }) => marginLeft ?? 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 5}px;
  margin-top: ${({ marginTop }) => marginTop ?? 0}px;

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ link }) =>
    link &&
    css`
      color: ${({ theme }) => theme.colors.principalColor};
      text-decoration: underline;
      cursor: pointer;
    `}

  ${({ principal }) =>
    principal &&
    css`
      color: ${({ theme }) => theme.colors.principalColor};
    `}

    ${({ gray }) =>
    gray &&
    css`
      color: ${({ theme }) => theme.colors.gray};
    `}

    ${({ white }) =>
    white &&
    css`
      color: white;
    `}

  @media (max-width: 768px) {
    font-size: 0.7em;
  }

  @media (max-width: 425px) {
    font-size: 0.6em;
  }
`
