import { useEffect, useState } from 'react'

export const useTheme = () => {
  const [theme, setTheme] = useState('light')
  const [componentMounted, setComponentMounted] = useState(false)

  const setMode = (mode, preference) => {
    window.localStorage.setItem(preference ? 'theme-preference' : 'theme', mode)
    setTheme(mode)
  }

  const toggleTheme = () => {
    if (theme === 'light') {
      setMode('dark', true)
    } else {
      setMode('light', true)
    }
  }

  useEffect(() => {
    getTheme()
    window.matchMedia('(prefers-color-scheme: dark)').addListener(() => getTheme())
    return window.matchMedia('(prefers-color-scheme: dark)').removeListener(() => getTheme())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTheme = () => {
    const themePreference = window.localStorage.getItem('theme-preference')
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches &&
    !themePreference
      ? setMode('dark')
      : themePreference
      ? setTheme(themePreference)
      : setMode('light')
    setComponentMounted(true)
  }

  return [theme, toggleTheme, componentMounted]
}
