import styled, { css } from 'styled-components'
import { FlexColumn, CircleImage, OutlineButton, PrimaryInput, ListTile } from 'styles/components'
import { AnimatedGradient } from 'styles/keyframes/animations'
import { ResumeTitle, CircularLoader } from 'styles/components/page'

export const ButtonsContainer = styled(FlexColumn)`
  flex-direction: row;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${OutlineButton} {
    margin: 0px 10px 0px 0px;
  }
`
export const SearchBar = styled.div`
  min-width: 235px;
  position: relative;

  &:after {
    content: '\f002';
    font-family: 'Font Awesome 5 Free';
    font-weight: bold;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    color: ${({ theme }) => theme.colors.principalColor};
    right: 15px;
    display: flex;
    justify-content: unset;
    align-items: center;
  }

  ${PrimaryInput} {
    height: 40px;
    border-radius: 4px;
  }
`

export const EmployeeTile = styled(ListTile)`
  --borderWidth: 3px;
  border-radius: var(--borderWidth);
  ${({ birthday }) =>
    birthday &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
        background: linear-gradient(
          60deg,
          #f79533,
          #f37055,
          #ef4e7b,
          #a166ab,
          #5073b8,
          #1098ad,
          #07b39b,
          #6fba82
        );
        border-radius: var(--borderWidth);
        z-index: 1;
        animation: ${AnimatedGradient} 3s ease alternate infinite;
        background-size: 300% 300%;
      }
    `}
`

export const EmployeeActions = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  width: 10%;
  height: 100%;
  transition: all 0.4s ease;
  opacity: ${({ open }) => (open ? 1 : 0)};
  justify-content: flex-end;
  align-items: center;

  ${({ theme }) =>
    theme &&
    css`
      background: linear-gradient(
        270deg,
        ${theme.colors.backgroundContainerHover} 0%,
        ${theme.colors.backgroundContainerHover} 79%,
        rgba(0, 212, 255, 0) 100%
      );
    `}
`
export const EmployeeAction = styled.div`
  width: 100%;
  height: 20%;
  margin: 5px 0px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EmployeeInfo = styled(ResumeTitle)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black};
  margin: 5px 0px 0px 0px;
  font-weight: 400;

  @media (max-width: 425px) {
    font-size: 0.8em;
  }
`

export const EmployeeInfoDescription = styled(ResumeTitle)`
  margin: 0px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: 400;
  text-indent: 5px;

  @media (max-width: 425px) {
    font-size: 0.8em;
  }

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      display: block;
      white-space: pre;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (max-width: 425px) {
        width: 60%;
      }
    `}
`
export const EmployeeRegistration = styled(ResumeTitle)`
  position: absolute;
  right: 2%;
  top: 5px;
  margin: 0px;
  font-size: 0.9em;
  color: ${({ theme }) => theme.colors.principalColor};
`

export const EmployeeName = styled(ResumeTitle)`
  color: ${({ theme }) => theme.colors.principalDark};
  margin: 0px 0px 10px 0px;
  font-weight: 700;
`

export const UploadPhotoContainer = styled(FlexColumn)`
  position: relative;
  flex: 1;

  ${CircleImage} {
    height: 70px;
    width: 70px;
    object-fit: cover;
  }

  ${CircularLoader} {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 70px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);

    &:after {
      margin: 0;
      border-bottom: 2px solid ${({ theme }) => theme.colors.principalColor};
      border-right: 2px solid ${({ theme }) => theme.colors.principalColor};
    }
  }

  ${({ canEdit }) =>
    canEdit &&
    css`
      &:after {
        content: '\f030';
        font-family: 'Font Awesome 5 Free';
        font-weight: bold;
        color: ${({ theme }) => theme.colors.principalColor};
        font-size: 1em;
        background: ${({ theme }) => theme.colors.principalWhite};
        width: 30px;
        height: 30px;
        display: flex;
        position: absolute;
        bottom: -10px;
        right: 0;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: all 0.4s ease;
      }

      &:hover:after {
        transform: scale(1.01);
        background: ${({ theme }) => theme.colors.principalColor};
        color: ${({ theme }) => theme.colors.principalWhite};
      }
    `}
`
