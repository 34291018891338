import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

export function durationStringTemplate() {
  return this.duration.days() >= 1 ? 'hh:mm[+]d' : 'hh:mm'
}

export function durationToString(duration) {
  return moment.duration(duration).format(durationStringTemplate)
}

export function durationToStringShift(duration) {
  const dur = moment.duration(duration)

  // Check if duration is greater than or equal to one day
  if (dur.days() >= 1) {
    // Format with days, hours, and minutes
    return dur.format('hh:mm [+] d [(finaliza no dia seguinte)]')
  } else {
    // Format with hours and minutes
    const hours = Math.floor(dur.asHours()) // Get total hours
    const minutes = dur.minutes() // Get remaining minutes

    // Format hours and minutes with leading zeros
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')

    // Combine formatted hours and minutes
    return `${formattedHours}:${formattedMinutes}`
  }
}

export function convertDurationToTimeStamp(duration) {
  let value = duration.split(':')[0]
  if (parseInt(value) > 23) {
    let days = 0
    let decrement = parseInt(value)

    while (decrement > 0) {
      if (parseInt(decrement) > 23) {
        days++
        decrement -= 24
      } else break
    }

    let arrayLen = duration.split(':')
    let newTimeArray = arrayLen.slice(1, arrayLen.length)
    let newTime = `${days}.${
      `${decrement}`.length === 1 ? `0${decrement}` : decrement
    }:${newTimeArray.join(':')}`

    return newTime
  }

  return duration
}
