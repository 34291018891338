import React from 'react'
import { FlexColumn, IconButton } from 'styles/components'

const SortButton = ({ sortDown, sortUp, hiddenUp, hiddenDown }) => {
  return (
    <FlexColumn justify={'center'} marginLeft={10}>
      <IconButton
        className={'fas fa-sort-up'}
        pointer
        hidden={hiddenUp}
        lineHeight={0.7}
        onClick={sortUp}
      />
      <IconButton
        className={'fas fa-sort-down'}
        pointer
        lineHeight={0.7}
        hidden={hiddenDown}
        onClick={sortDown}
      />
    </FlexColumn>
  )
}

export default SortButton
