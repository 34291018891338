import 'core-js/es6/string'
import 'core-js/es6/array'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/object'
import 'core-js/es6/promise'
import 'core-js/es7/object'
import 'core-js/es7/array'
import 'raf/polyfill'
import './config/axios.config'
import 'semantic-ui-css/semantic.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './i18n'

import { Provider } from 'mobx-react'
import stores from './stores'

require('dotenv').config()

window._____APP_STATE_____ = stores

ReactDOM.render(
  <Provider rootStore={stores}>
    <App />
  </Provider>,
  document.getElementById('app')
)
