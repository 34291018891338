import axios from 'axios'
import defaultResponse from '../../models/http/defaultResponse'
import { extractError } from '../utils/errorHandler'

export const recover = async (email) => {
  try {
    const response = await axios.post('user/sendPasswordResetMail', {
      emailAddress: email,
    })
    return new defaultResponse(response.status === 200, response.data)
  } catch (err) {
    const apiError = extractError(err)
    return new defaultResponse(false, apiError)
  }
}

export const resetPassword = async (email, token, password) => {
  try {
    const response = await axios.post('user/resetPassword', {
      emailAddress: email,
      token: token,
      password,
    })
    return new defaultResponse(response.status === 200, response.data)
  } catch (err) {
    const apiError = extractError(err)
    return new defaultResponse(false, apiError)
  }
}
