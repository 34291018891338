import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAll = async ({ includeShifts = false } = {}) => {
  try {
    const query = {}
    if (includeShifts) {
      query.include = [query.include, 'shifts'].filter(Boolean).join(',')
    }

    const { data: workStations } = await axios.get('workStation', { params: query })
    return workStations
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getCurrentStatus = async (id, axiosCancellationToken) => {
  try {
    const { data: currentStatus } = await axios.get(`workStation/${id}/currentStatus`, {
      cancelToken: axiosCancellationToken,
    })
    return currentStatus
  } catch (err) {
    const apiError = extractError(err)
    console.error(apiError)
    return null
  }
}

export const getWorkStationPauseHistory = async (id, beginPlannedInterval, endPlannedInterval) => {
  try {
    const { data: workStationPauseHistory } = await axios.get(`workStation/${id}/pauseHistory`, {
      params: {
        from: beginPlannedInterval,
        to: endPlannedInterval,
      },
    })
    return workStationPauseHistory
  } catch (err) {
    const apiError = extractError(err)
    console.error(apiError)
    return null
  }
}

export const getById = async (id, { includeShifts = false, includeTools = false } = {}) => {
  try {
    const query = {}
    if (includeShifts) {
      query.include = [query.include, 'shifts'].filter(Boolean).join(',')
    }
    if (includeTools) {
      query.include = [query.include, 'tools'].filter(Boolean).join(',')
    }

    const { data: workStation } = await axios.get(`workStation/${id}`, {
      params: query,
    })
    return workStation
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const remove = async (id) => {
  try {
    await axios.delete(`workStation/${id}`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (workStation) => {
  try {
    const { data: updatedWorkStation } = await axios.put(
      `workStation/${workStation.id}`,
      workStation
    )
    return updatedWorkStation
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (workStation) => {
  try {
    const { data: newWorkStation } = await axios.post('workStation', workStation)
    return newWorkStation
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getLoggedEmployees = async (id) => {
  try {
    const { data: loggedEmployees } = await axios.get(`workStation/${id}/loggedEmployees`)
    return loggedEmployees
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getStoppedEmployees = async (id) => {
  try {
    const { data: employees } = await axios.get('workStation/allEmployeesOnShiftNotWorking')
    return employees
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
