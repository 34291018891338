export default class defaultResponse {
  constructor(success, data) {
    this.success = success
    this.data = data
  }
  get isSuccess() {
    return this.success
  }
  get getData() {
    return this.data
  }
}
