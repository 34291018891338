import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const validateEmployeeHasTraining = async (registration, trainingId) => {
  try {
    const { data: expired } = await axios.get(`training/${trainingId}/validate/${registration}`)
    return expired
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const declareEmployeeHasTraining = async (employeeTraining) => {
  try {
    await axios.post('Training/DeclareEmployeeHasTraining', employeeTraining)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
