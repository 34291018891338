import { observable, action, reaction } from 'mobx'

export default class ApplicationStore {
  @observable settings = {
    isFixed: true,

    /* Sidebar collapsed */
    isCollapsed: true,

    /* Boxed layout */
    isBoxed: false,

    /* Floating sidebar */
    isFloat: false,

    /* Sidebar show menu on hover only */
    asideHover: false,

    /* Show sidebar scrollbar (dont' hide it) */
    asideScrollbar: false,

    /* Sidebar collapsed with big icons and text */
    isCollapsedText: false,

    /* Toggle for the offsidebar */
    offsidebarOpen: false,

    /* Toggle for the sidebar offcanvas (mobile) */
    asideToggled: false,

    /* Toggle for the sidebar user block */
    showUserBlock: false,

    /* Enables layout horizontal */
    horizontal: false,

    /* Full size layout */
    useFullLayout: false,

    /* Hide footer */
    hiddenFooter: false,
  }

  constructor(rootStore) {
    this.rootStore = rootStore
    reaction(
      () => this.settings,
      (settings) => {
        this.changeSettings()
      }
    )

    this.changeSettings()
  }

  @action changeSettings() {
    const updateElementClass = (el, stat, name) => el && el.classList[stat ? 'add' : 'remove'](name)
    const updateBodyClass = (stat, name) => updateElementClass(document.body, stat, name)
    updateBodyClass(this.settings.isFixed, 'layout-fixed')
    updateBodyClass(this.settings.isBoxed, 'layout-boxed')
    updateBodyClass(this.settings.isCollapsed, 'aside-collapsed')
    updateBodyClass(this.settings.isCollapsedText, 'aside-collapsed-text')
    updateBodyClass(this.settings.isFloat, 'aside-float')
    updateBodyClass(this.settings.asideHover, 'aside-hover')
    updateBodyClass(this.settings.offsidebarOpen, 'offsidebar-open')
    updateBodyClass(this.settings.asideToggled, 'aside-toggled')
    // layout horizontal
    updateBodyClass(this.settings.horizontal, 'layout-h')
    // apply change to the sidebar element
    updateElementClass(
      document.querySelector('.sidebar'),
      this.settings.asideScrollbar,
      'show-scrollbar'
    )
  }

  @action toggleSetting(setting) {
    this.settings = {
      ...this.settings,
      [setting]: !this.settings[setting],
    }
  }
}
