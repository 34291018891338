import { observable, action, reaction } from 'mobx'
import * as workStationService from '../services/resources/workStation.service'
import { pauseService } from 'services'

export default class WorkStationStore {
  @observable workStations = []
  @observable pauses = []

  constructor(rootStore) {
    this.rootStore = rootStore

    reaction(
      () => this.rootStore.authStore.accessToken,
      () => {
        if (!this.rootStore.authStore.accessTokenExpired) {
          this.getAllWorkStationsFromApi()
          this.loadPauses()
        }
      }
    )
  }

  @action getAllWorkStationsFromApi() {
    return workStationService
      .getAll()
      .then(action((response) => (this.workStations = response)))
      .catch((err) => {
        throw err
      })
  }

  @action getAreaByIdFromApi(id) {
    return workStationService
      .getById(id)
      .then(action((area) => this.updateOne(area)))
      .catch((err) => {
        throw err
      })
  }

  @action loadPauses() {
    return pauseService.getAllMachinePauses().then(action((response) => (this.pauses = response)))
  }

  @action removeOne(workStationId) {
    this.workStations = this.workStations.filter((a) => a.id !== workStationId)
  }

  @action updateOne(workStation) {
    const workStationIndex = this.workStations.findIndex((a) => a.id === workStation.id)
    this.workStations[workStationIndex] = workStation
  }

  @action addOne(workStation) {
    this.workStations = this.workStations.concat(workStation)
  }
}
