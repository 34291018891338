import styled, { css } from 'styled-components'
import { FlexContainer, PageTitle } from 'styles/components'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
`

export const Label = styled(PageTitle)`
  line-height: 20px;
  font-weight: bold;
  font-size: 0.9em;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0px 0px 5px 0px;

  @media (max-width: 768px) {
    font-size: 0.7em;
  }

  @media (max-width: 425px) {
    font-size: 0.6em;
  }
`

export const ChipsContainer = styled(FlexContainer)`
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: start;
  row-gap: 5px;
`

export const Chip = styled.div`
  border-radius: 4px;
  display: flex;
  font-size: 12px;
  border: 2px solid ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.textColor};
  transition: all 0.4s ease;
  padding: 4px 5px;
  margin-right: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 5px;
  }

  @media (max-width: 425px) {
    margin: 5px;
  }

  &:after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.principalWhite};
    font-weight: bold;
    opacity: 0;
    margin-left: 10px;
    filter: brightness(0.9);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.principalWhite};

    &:after {
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${({ theme }) => theme.colors.success};
      color: ${({ theme }) => theme.colors.principalWhite};
      &:after {
        opacity: 1;
      }

      &:hover {
        background: ${({ theme }) => theme.colors.danger} !important;
        color: ${({ theme }) => theme.colors.principalWhite};
        border: 2px solid ${({ theme }) => theme.colors.danger} !important;

        &:after {
          content: '\f00d';
          background-color: ${({ theme }) => theme.colors.danger};
        }
      }
    `}
`
