import { reaction } from 'mobx'
import BaseHub from './baseHub'

export default class MainHub extends BaseHub {
  constructor(rootStore) {
    super('general', rootStore)
    this.rootStore = rootStore
    
    reaction(
      () => this.rootStore.authStore.accessToken,
      () => {
        if (!this.rootStore.authStore.accessTokenExpired) this.beginConnection()
        // this.disconnect()
      }
    )
  }

  configureHub() {
    super.configureHub()
    this.connection.on('AreaAdded', (area) => {
      this.rootStore.areaStore.addOne(area)
    })

    this.connection.on('AreaUpdated', (area) => {
      this.rootStore.areaStore.updateOne(area)
    })

    this.connection.on('AreaRemoved', (areaId) => {
      this.rootStore.areaStore.removeOne(areaId)
    })

    this.connection.on('WorkStationAdded', (workStation) => {
      this.rootStore.workStationStore.addOne(workStation)
    })

    this.connection.on('WorkStationUpdated', (workStation) => {
      this.rootStore.workStationStore.updateOne(workStation)
    })

    this.connection.on('WorkStationRemoved', (workStationId) => {
      this.rootStore.workStationStore.removeOne(workStationId)
    })
  }

  addArea(area) {
    this.connection && this.connection.invoke('AddArea', area)
  }

  updateArea(area) {
    this.connection && this.connection.invoke('UpdateArea', area)
  }

  removeArea(area) {
    this.connection && this.connection.invoke('RemoveArea', area)
  }

  addWorkStation(workStation) {
    this.connection && this.connection.invoke('AddWorkStation', workStation)
  }

  updateWorkStation(workStation) {
    this.connection && this.connection.invoke('UpdateWorkStation', workStation)
  }

  removeWorkStation(workStation) {
    this.connection && this.connection.invoke('RemoveWorkStation', workStation)
  }
}
