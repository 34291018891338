import styled, { css } from 'styled-components'
import { PrimaryInput, PageTitle } from 'styles/components'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 10px;

  ${({ stretch }) =>
    stretch &&
    css`
      width: 100%;
    `}

  ${({ vertical }) =>
    vertical &&
    css`
      display: flex;
      flex-direction: row;
      margin-bottom: 0px;

      h1 {
        margin-bottom: 0px;
        margin-right: 10px;
        white-space: pre;
      }
    `}
`

export const Label = styled(PageTitle)`
  line-height: 20px;
  font-weight: bold;
  font-size: 0.9em;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0px 0px 5px 0px;

  @media (max-width: 768px) {
    font-size: 0.7em;
  }

  @media (max-width: 425px) {
    font-size: 0.6em;
  }
`

export const Input = styled(PrimaryInput)`
  ${({ required }) =>
    required &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.danger};

      &:valid {
        border-bottom: 2px solid ${({ theme }) => theme.colors.baseInputBorderColor};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.danger} !important;
    `}
`

export const ErrorMessage = styled(PageTitle)`
  font-size: 0.7em;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.danger};
  margin: 0px 0px 5px 0px;
  position: absolute;
`

export const Subtitle = styled(PageTitle)`
  font-size: 0.7em;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.principalColor};
  margin: 0px 0px 5px 0px;
  position: absolute;
`
