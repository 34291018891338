import { AZURE_FUNCTIONS } from 'config/app.config'

export const uploadPhoto = async (photo, clientId, fileName) => {
  let formData = new FormData()
  formData.append('profile', photo)
  try {
    const data = await fetch(
      `${AZURE_FUNCTIONS}/uploadImageFile?code=1K2bMW9kFD2EZEKH2xBt2qwR22CbBGm5BtaOAQrazznfmYdwLHNjrQ==&clientId=${clientId}-process&fileName=${fileName}`,
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }
    )

    return data
  } catch (err) {
    throw new Error()
  }
}

export const uploadVideo = async (video, clientId, fileName) => {
  let formData = new FormData()
  formData.append('profile', video)
  try {
    const data = await fetch(
      `${AZURE_FUNCTIONS}/UploadVideoFile?code=NORqftSv69pLPGSBuzXUXExKDHaMr38c4JcT8FV3LLaUCbWCmXkjVA==&clientId=${clientId}-process&fileName=${fileName}`,
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }
    )

    return data
  } catch (err) {
    throw new Error()
  }
}

export const uploadFile = async (video, clientId, fileName) => {
  let formData = new FormData()
  formData.append('profile', video)
  try {
    const data = await fetch(
      `${AZURE_FUNCTIONS}/UploadFile?code=2Cc1BUcbMmdksjeMOP0gyVdv_AxIRDn7OdJWrYbxqsuMAzFuvatitA==&clientId=${clientId}-process&fileName=${fileName}`,
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }
    )

    return data
  } catch (err) {
    throw new Error()
  }
}

export const uploadAudio = async (audio, clientId, fileName) => {
  let formData = new FormData()
  formData.append('profile', audio)
  try {
    const data = await fetch(
      `${AZURE_FUNCTIONS}/UploadAudioFile?code=3wwdRVFwOBucQbpvGItuZTiz7eyiM2KfGH1n9HTshH3yZ8pLQ/HAPw==&clientId=${clientId}-process&fileName=${fileName}`,
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }
    )

    return data
  } catch (err) {
    throw new Error()
  }
}
