import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getById = async (id) => {
  try {
    const { data: productionAlerts } = await axios.get(`productionAlert/${id}`)
    return productionAlerts
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (productionAlert) => {
  try {
    const { data: newProductionAlert } = await axios.post('productionAlert', productionAlert)
    return newProductionAlert
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const acknowledge = async (id, acknowledgeEmployee) => {
  try {
    const { data: productionAlertResponse } = await axios.post(
      `productionAlert/${id}/acknowledge`,
      acknowledgeEmployee
    )
    return productionAlertResponse
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const close = async (id, closeEmployee) => {
  try {
    const { data: productionAlertResponse } = await axios.post(
      `productionAlert/${id}/close`,
      closeEmployee
    )
    return productionAlertResponse
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getByWorkStationAndOpenedDate = async (
  workStationId,
  beginInterval = null,
  endInterval = null
) => {
  try {
    let dateFilters = beginInterval ? `/beginInterval=${beginInterval}` : ''
    dateFilters = dateFilters + beginInterval ? `/endInterval=${endInterval}` : ''
    const { data: productionAlerts } = await axios.get(
      `productionAlert/workStation/${workStationId}${dateFilters}`
    )
    return productionAlerts
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getByWorkOrderAndOpenedDate = async (
  workOrderId,
  beginInterval = null,
  endInterval = null
) => {
  try {
    let dateFilters = beginInterval ? `/beginInterval=${beginInterval}` : ''
    dateFilters = dateFilters + beginInterval ? `/endInterval=${endInterval}` : ''
    const { data: productionAlerts } = await axios.get(
      `productionAlert/workOrder/${workOrderId}${dateFilters}`
    )
    return productionAlerts
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllByOpenedDate = async (beginInterval = null, endInterval = null) => {
  try {
    let dateFilters = beginInterval ? `/beginInterval=${beginInterval}` : ''
    dateFilters = dateFilters + beginInterval ? `/endInterval=${endInterval}` : ''
    const { data: productionAlerts } = await axios.get(`productionAlert${dateFilters}`)
    return productionAlerts
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

//NOT CLOSED
export const getNotClosed = async () => {
  try {
    const { data: productionAlerts } = await axios.get('productionAlertNotClosed')
    return productionAlerts
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getNotClosedByWorkStation = async (id) => {
  try {
    const { data: productionAlerts } = await axios.get(`productionAlertNotClosed/workStation/${id}`)

    return productionAlerts
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
