import { observable, action, reaction } from 'mobx'
import * as areaService from '../services/resources/area.service'

export default class AreaStore {
  @observable areas = []
  @observable areasMenu = []

  constructor(rootStore) {
    this.rootStore = rootStore

    reaction(
      () => this.rootStore.authStore.selectedTenant,
      (tenant) => {
        this.areasMenu = localStorage.getItem(tenant + '-areasMenu')
          ? JSON.parse(localStorage.getItem(tenant + '-areasMenu'))
          : []

        reaction(
          () => this.areasMenu,
          async (areasMenu) => {
            if (areasMenu)
              window.localStorage.setItem(tenant + '-areasMenu', JSON.stringify(areasMenu))
          }
        )
      }
    )

    reaction(
      () => this.rootStore.authStore.accessToken,
      () => {
        if (!this.rootStore.authStore.accessTokenExpired) this.getAllAreasFromApi()
      }
    )
  }

  @action getAllAreasFromApi() {
    return new Promise((resolve) => {
      areaService
        .getAll()
        .then(
          action((response) => {
            this.areas = response
            resolve()
          })
        )
        .catch((err) => {
          throw err
        })
    })
  }

  @action getAreaByIdFromApi(id) {
    return areaService
      .getById(id)
      .then(action((area) => this.updateOne(area)))
      .catch((err) => {
        throw err
      })
  }

  @action addOne(area) {
    this.areas.push(area)
  }

  @action updateOne(area) {
    const areaIndex = this.areas.findIndex((a) => a.id === area.id)
    this.areas[areaIndex] = area
  }

  @action removeOne(areaId) {
    this.areas = this.areas.filter((a) => a.id !== areaId)
  }

  @action updateAreaOrdering(areasSort) {
    this.areasMenu = areasSort
  }
}
