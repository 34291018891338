import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getAll = async () => {
  try {
    const { data: shifts } = await axios.get('shift')
    return shifts
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getById = async (id) => {
  try {
    const { data: shift } = await axios.get(`shift/${id}`)
    return shift
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const remove = async (id) => {
  try {
    await axios.delete(`shift/${id}`)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const update = async (shift) => {
  try {
    const { data: updatedShift } = await axios.put(`shift/${shift.id}`, shift)
    return updatedShift
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (shift) => {
  try {
    const { data: newShift } = await axios.post('shift', shift)
    return newShift
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
