import styled, { css } from 'styled-components'
import { Disappear } from '../keyframes/animations'

export const UserTileSkeleton = styled.div`
  width: 100%;
  height: 80px;
  border: none;
  background: #c0c0c0;
  animation: ${Disappear} 1.5s ease-in-out infinite;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;

  &:after {
    content: '';
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #9a9a9a;
    display: block;
  }

  &:before {
    content: '';
    height: 20px;
    width: 70%;
    background: #9a9a9a;
    display: block;
    margin-left: 10px;
    margin-bottom: 15px;
  }
`
export const TileSkeleton = styled.div`
  width: 90%;
  margin: 10px auto;
  height: 80px;
  border: none;
  background: ${({ theme }) => theme.colors.backgroundDefaultTile};
  animation: ${Disappear} 1.5s ease-in-out infinite;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  border-radius: 4px;

  &:before {
    content: '';
    height: 20px;
    width: 70%;
    background: #d8d8d8;
    display: block;
    margin-left: 10px;
    margin-bottom: 15px;
  }
`

export const TabSkeleton = styled.div`
  width: 100%;
  margin: 10px auto;
  height: 80px;
  border: none;
  background: ${({ theme }) => theme.colors.backgroundDefaultTile};
  animation: ${Disappear} 1.5s ease-in-out infinite;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  border-radius: 4px;

  &:before {
    content: '';
    height: 20px;
    width: 90%;
    background: #d8d8d8;
    display: block;
    margin-left: 10px;
    margin-bottom: 15px;
  }
`

export const UserLetter = styled.div`
  height: 70px;
  width: 70px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.colors.userTileColors[Math.floor(Math.random() * (4 - 0)) + 0]};
  border-radius: 50%;
  margin-right: ${({ marginRight }) => marginRight ?? 0}em;
  
  ${({ fill }) =>
    fill &&
    css`
      width: 100%;
      height: 100%;
      &:after {
        font-size: 14px;
      }
    `}

  ${({ size }) =>
    size &&
    css`
      width: ${size}px;
      height: ${size}px;
      &:after {
        font-size: 14px;
      }
    `}
&:after
{
  content: "${({ letter }) => letter}";
  color: ${({ theme }) => theme.colors.principalWhite};
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
}
`
