import React, { useState, useEffect, memo } from 'react'
import { withNamespaces } from 'react-i18next'
import { inject } from 'mobx-react'
import { BLOB_STORAGE } from 'config/app.config'
import LoadingGif from 'images/gifs/loading.gif'
import { Profile, OptionsWrapper, OptionsBase, Label } from '../styles'
import { CircleImage, UserLetter, FlexColumn } from 'styles/components'
import Link from './link'
import i18n from 'i18next'
import ModalEmployee from './modalEmployee'
import { withNotification } from 'hoocs/index'

const ProfileWrapper = ({ t, rootStore, employee, reloadEmployee, successNotification }) => {
  const { email, selectedTenant, availableTenants } = rootStore.authStore
  // const showLanguage = email === 'superuser@plantscanner.io'
  const isStaging =
    window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')

  const [selectedMenu, setSelectedMenu] = useState({ menu: undefined, top: 0, right: 0 })
  const [menuOpen, setMenuOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)

  const handleClick = (event) => {
    if (!menuOpen) return
    setMenuOpen(false)
    setSelectedMenu((prev) => ({ ...prev, menu: undefined }))
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  })

  const profileOptions = [
    employee && {
      title: t('sidebar.profile.myProfile'),
      action: () => {
        setProfileOpen(true)
      },
    },
    {
      title: t('sidebar.profile.translate'),
      subMenus: [
        {
          title: t('sidebar.profile.english'),
          translate: 'en-US',
          action: () => {
            i18n.changeLanguage('en-US')
          },
        },
        {
          title: t('sidebar.profile.portuguese'),
          translate: 'pt-BR',
          action: () => {
            i18n.changeLanguage('pt-BR')
          },
        },
        {
          title: t('sidebar.profile.spanish'),
          translate: 'es',
          action: () => {
            i18n.changeLanguage('es')
          },
        },
      ],
    },
    Array.isArray(availableTenants) && {
      title: t('sidebar.profile.changeTenant'),
      subMenus: availableTenants
        .sort((a, b) => a.localeCompare(b))
        .map((t) => ({
          title: t,
          translate: '',
          action: async () => {
            await rootStore.authStore.login(t)
            setTimeout(async () => {
              await rootStore.authStore.selectTenant(t)
            }, 1000)
          },
        })),
    },
    {
      title: t('sidebar.profile.logout'),
      path: 'logout',
    },
  ]

  const showSubMenu = (e) => {
    const pos = e.target.getBoundingClientRect()
    setSelectedMenu({
      menu: e.target.getAttribute('name'),
      top: pos.top - pos.height * 2,
      right: pos.width,
    })
  }

  return (
    <Profile>
      {employee && (
        <FlexColumn direction={'row'} onClick={() => setMenuOpen(!menuOpen)}>
          <FlexColumn align="flex-end">
            <Label>{employee.shortName}</Label>
            <Label tenant>
              {isStaging && (
                <span
                  style={{
                    opacity: 0.6,
                  }}
                >
                  staging -{' '}
                </span>
              )}
              {selectedTenant}
            </Label>
          </FlexColumn>
          <CircleImage
            src={employee ? `${BLOB_STORAGE}/${selectedTenant}/employee-${employee?.id}.jpg` : ''}
            fallbackImage={
              <UserLetter
                size={40}
                key={employee.id}
                letter={employee.shortName[0] ?? employee.fullName[0]}
              />
            }
            initialImage={LoadingGif}
            alt={employee?.shortName}
            maxSize={40}
          />
        </FlexColumn>
      )}
      {!employee && (
        <FlexColumn key={email} direction={'row'} onClick={() => setMenuOpen(!menuOpen)}>
          <FlexColumn align="flex-end">
            <Label>{email}</Label>
            <Label tenant>
              {isStaging && (
                <span
                  style={{
                    opacity: 0.6,
                  }}
                >
                  staging -{' '}
                </span>
              )}
              {selectedTenant}
            </Label>
          </FlexColumn>
          <UserLetter size={40} key={email} letter={(email && email[0]) || 'P'} />
        </FlexColumn>
      )}
      <OptionsWrapper isOpen={menuOpen} width={180} right={0.1} inline direction={'top right'}>
        {profileOptions
          .filter((p) => p)
          .map((p) => {
            return (
              <>
                <Link isActive={!p.subMenus && !p.action} to={p.path}>
                  <OptionsBase
                    name={p.title}
                    arrowLeft={!!p.subMenus}
                    key={p.title}
                    onMouseEnter={showSubMenu}
                    onClick={p.action}
                    end
                  >
                    {p.title}
                  </OptionsBase>
                  {p.subMenus && (
                    <OptionsWrapper
                      autoWidth
                      isOpen={selectedMenu.menu === p.title}
                      top={selectedMenu.top}
                      right={selectedMenu.right}
                    >
                      {p.subMenus
                        ?.filter(
                          (s) => i18n.language.indexOf(s.translate) === -1 || s.translate === ''
                        )
                        .map((subMenu) => (
                          <OptionsBase key={subMenu.title} onClick={subMenu.action}>
                            {subMenu.title}
                          </OptionsBase>
                        ))}
                    </OptionsWrapper>
                  )}
                </Link>
              </>
            )
          })}
      </OptionsWrapper>
      {profileOpen && (
        <ModalEmployee
          open={profileOpen}
          closeFn={() => setProfileOpen(false)}
          editing={employee}
          updateEmployee={async () => {
            await reloadEmployee()
            setProfileOpen(false)

            successNotification('Perfil atualizado', 3000)
          }}
        />
      )}
    </Profile>
  )
}

export default withNamespaces()(inject('rootStore')(memo(withNotification(ProfileWrapper))))
